import { ControlsContainer, FullScreenControl, SigmaContainer, ZoomControl } from '@react-sigma/core';

import { DirectedGraph } from 'graphology';
import GraphEventsController from '_pages/targetvalidation/graphDisease/graphology/GraphEventsController.Demo';
import GraphDataController from './graphology/GraphDataController';
import GraphSettingsController from '_pages/targetvalidation/graphDisease/graphology/GraphSettingsController';

import '@react-sigma/core/lib/react-sigma.min.css';
import { useState } from 'react';
import SearchField from './graphology/UIControls/SearchField';
import Filters from './graphology/UIControls/Filters';
import { Paper } from '@mui/material';
import customTheme from '_theme/theme';

const SIMGA_SETTINGS = {
  defaultEdgeType: 'arrow',
  defaultEdgeColor: '#bcbcbc',
  labelDensity: 0.07,
  labelGridCellSize: 60,
  // labelRenderedSizeThreshold: 15,
  labelFont: 'Lato, sans-serif',
  zIndex: true,
};

/**
 * Renders sigma/graphology graph with external suggestion/selector
 * @todo find appropriate name
 * @param {{diseaseData: Array}} props
 */
function SigmaDiseaseGraph({ diseaseData, onGetNodeInfo, onResetEdges }) {
  const [hoveredNode, setHoveredNode] = useState(null);

  return (
    <SigmaContainer
      graphOptions={{ type: 'directed' }}
      settings={SIMGA_SETTINGS}
      style={{ border: '1px solid grey', height: '700px' }}
      graph={DirectedGraph}
    >
      <ControlsContainer position="bottom-right" className="noBorderControlsContainer">
        <Paper variant="outlined">
          <ZoomControl />

          <FullScreenControl />
        </Paper>
      </ControlsContainer>
      <ControlsContainer className="noBorderControlsContainer" position="top-right">
        <Paper
          variant="outlined"
          sx={{ paddingTop: 1, paddingBottom: 1, backgroundColor: customTheme.palette.background.default }}
        >
          <SearchField />
          <Filters />
        </Paper>
      </ControlsContainer>

      <GraphSettingsController hoveredNode={hoveredNode} />
      <GraphDataController diseaseData={diseaseData} />

      <GraphEventsController
        onGetNodeInfo={onGetNodeInfo}
        setHoveredNode={setHoveredNode}
        onResetEdges={onResetEdges}
      />
    </SigmaContainer>
  );
}

export default SigmaDiseaseGraph;
