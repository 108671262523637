import { Router, Switch, Route } from 'react-router-dom';
import history from '_helpers/history';
import config from '_configs';
import FileUploads from '_pages/FileUploads/FileUploads';
import Visualization from '_pages/visualization/Visualization';
import VisProject from '_pages/visualization/VisProject';
import TargetValidation from '_pages/targetvalidation/TargetValidation';
import GraphDiseasePage from '_pages/targetvalidation/graphDisease/GraphDiseasePage';
import NodeProcessPathsPage from '_pages/targetvalidation/nodeProcessComponents/NodeProcessPathsPage';
import Profile from '_pages/profile/Profile';
import FrontPage from '_pages/frontpage/Frontpage';
import seedrandom from 'seedrandom';
import { ToastContainer } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import Loading from '_components/Loading';
import Footer from '_components/layout/Footer';
import PermanentDrawerLeft from '_components/layout/DrawerComponent';
import SSEHub from '_components/sse/SSEHub';
import 'react-toastify/dist/ReactToastify.css';
import '_css/layout.css';
import Projects from '_pages/Projects/Projects';
import Models from '_pages/Models/Models';
import Home from '_pages/Home';
import theme from '_theme/theme';
import Help from '_pages/Help';

seedrandom(process.env.REACT_APP_RANDOM_SEED || 1, { global: true });

function App() {
  const { isLoading, error, isAuthenticated } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        {!isAuthenticated && <FrontPage />}

        {isAuthenticated && (
          <Box sx={{ display: 'flex', paddingTop: '65px' }}>
            <PermanentDrawerLeft />
            <SSEHub />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Switch>
                <Route exact path={config.clientUrls.TargetValidation} component={TargetValidation} />
                <Route exact path={config.clientUrls.FILES} component={FileUploads} />
                <Route exact path={config.clientUrls.VISUALIZATION} component={Visualization} />
                <Route exact path={config.clientUrls.VISUALIZATION_PROJECT(':projectId')} component={VisProject} />
                <Route exact path={config.clientUrls.HOME} component={Home} />
                <Route exact path={config.clientUrls.GRAPHS_DISEASE(':disease')} component={GraphDiseasePage} />
                <Route exact path={config.clientUrls.PROFILE} component={Profile} />
                <Route exact path={config.clientUrls.PROJECTS} component={Projects} />
                <Route exact path={config.clientUrls.MODELS} component={Models} />
                <Route exact path={config.clientUrls.HELP} component={Help} />
                <Route
                  exact
                  path={config.clientUrls.NODE_PROCESS_PATHS(':disease', ':nodeId')}
                  component={NodeProcessPathsPage}
                />
              </Switch>
            </Box>
          </Box>
        )}

        <Footer />
      </ThemeProvider>
    </Router>
  );
}

export default App;
