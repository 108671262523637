import BasicModal from "_components/base/BasicModal";
import RenamePrompt from "_components/content/RenamePrompt";

interface SaveProjectModalProps {
    open: boolean;
    onCancel: () => void;
    onSubmit: (newName: string) => void;
}

const styles = {
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    }
}

export default function SaveProjectModal({ open = false, onSubmit, onCancel }: SaveProjectModalProps) {

    return (
        <BasicModal
            open={open}
            onClose={onCancel}
            aria-labelledby="save-project-modal-title"
            aria-describedby="save-project-modal-description"
            boxSx={styles.modal}
        >
            <RenamePrompt
                title="Create Project"
                onCancel={onCancel}
                onSubmit={(name, newName) => onSubmit(newName)}
                originalName=""
                renameLabel="Create"

            />
        </BasicModal>
    );

}