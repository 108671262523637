import { handleResponse } from '_helpers/middleware';

const URLS = {
    listUploads: '/api/harbor/uploads',
};

function listUploads(accessToken) {
    const requestOptions = {
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken} ` },
    };
    return fetch(URLS.listUploads, requestOptions).then(handleResponse);
}

const uploadsService = {
    listUploads,
    URLS,
};

export default uploadsService;
