import { Typography, Box, Container, Divider, Chip, Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import Paper from '@mui/material/Paper';
import { Auth0ContextInterface } from '@auth0/auth0-react';

const SIDEPADDING = 25;
const KEYS_WIDTH = '160px';

interface KeyValueDataEntryProps {
  entrykey: string;
  value: string;
}

function KeyValueDataEntry({ entrykey, value }: KeyValueDataEntryProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
      <Box sx={{ width: KEYS_WIDTH }}>
        <Typography>{entrykey}:</Typography>
      </Box>
      <Typography>{value}</Typography>
    </Box>
  );
}

interface KeyChipsDataEntryProps {
  entrykey: string;
  values: string[];
}

function KeyChipsDataEntry({ entrykey, values }: KeyChipsDataEntryProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
      <Box sx={{ width: KEYS_WIDTH }}>
        <Typography>{entrykey}:</Typography>
      </Box>
      {values.map((v) => (
        <Chip key={v} label={v} sx={{ marginRight: 1 }} color="primary" variant="outlined" />
      ))}
    </Box>
  );
}

interface ExtendedUser extends Auth0ContextInterface {
  email: string;
  picture: string;
  nickname: string;
  email_verified: boolean;
}

function PersonalInfo() {
  const { user } = useAuth0();
  const { picture, email, nickname, email_verified } = user as ExtendedUser;
  const roles: string[] = user?.['simmunomeplatform/roles'];
  return (
    <Paper elevation={3}>
      <Box sx={{ padding: 5, paddingLeft: SIDEPADDING, paddingRight: SIDEPADDING }}>
        <Divider>
          <Typography variant="h5">Personal Info</Typography>
        </Divider>
        <KeyValueDataEntry entrykey={'Name'} value={nickname}></KeyValueDataEntry>
        <KeyValueDataEntry entrykey={'Email'} value={email}></KeyValueDataEntry>
        <Divider sx={{ marginTop: 5 }}>
          <Typography variant="h5">Security</Typography>
        </Divider>
        <KeyValueDataEntry entrykey={'Email verified'} value={String(email_verified)}></KeyValueDataEntry>
        <KeyChipsDataEntry entrykey="Roles" values={roles}></KeyChipsDataEntry>
      </Box>
    </Paper>
  );
}

function CompanyInfo() {
  const company = 'Simmunome';
  const country = 'Canada';
  const currentTier = 'Premium';
  const remainingCredits = 5000;
  const availablefeatures = ['Target Validation', 'Visualization'];
  return (
    <Paper elevation={3}>
      <Box sx={{ padding: 5, paddingLeft: SIDEPADDING, paddingRight: SIDEPADDING }}>
        <Divider>
          <Typography variant="h5">Company Info</Typography>
        </Divider>

        <KeyValueDataEntry entrykey={'Company'} value={company}></KeyValueDataEntry>
        <KeyValueDataEntry entrykey={'Country'} value={country}></KeyValueDataEntry>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Box sx={{ flexShrink: 0, minWidth: '50%' }}>
            <KeyValueDataEntry entrykey={'Current Tier'} value={currentTier}></KeyValueDataEntry>
            <KeyValueDataEntry entrykey={'Remaining credits'} value={String(remainingCredits)}></KeyValueDataEntry>
          </Box>
          <Button variant="contained" sx={{ margin: 'auto' }}>
            Upgrade
          </Button>
        </Box>

        <KeyChipsDataEntry entrykey="Available Products" values={availablefeatures}></KeyChipsDataEntry>
      </Box>
    </Paper>
  );
}

export default function Profile() {
  return (
    <Container>
      <div className="header">
        <Box sx={{ marginTop: '10px', marginBottom: '10px' }}>
          <Typography variant="h4" color="primary">
            Simmunome Account
          </Typography>
        </Box>
      </div>
      <PersonalInfo></PersonalInfo>
      <Box sx={{ height: 40 }}></Box>
      <CompanyInfo />
    </Container>
  );
}
