import { useState, useEffect } from 'react';
import targetValidationService from '_services/target.validation.service';
import { useParams, useLocation } from 'react-router-dom';
import diseaseService from '_services/disease.service';
import { Box, Container, Typography, Button, Paper } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import nodeStore from '../SelectedNodeStore';
import config from '_configs';
import history from '_helpers/history';
import SigmaGraph from './SigmaDiseaseGraph';
import GraphLegendTable from './graphLegendTable';

import AiPredStore from '_pages/targetvalidation/FilterAIPredictionStore';

import '@react-sigma/core/lib/react-sigma.min.css';
import '_css/sigma.css';
import { withServiceCallHandling } from '_helpers/decorators';

function HelpText() {
  return (
    <Paper sx={{ padding: 1 }}>
      <Typography style={{ display: 'inline-block' }} sx={{ pb: 1 }}>
        The graph illustrates the network of proteins involved in diseases pathways. Red nodes denote molecular
        endpoints that signify disease progression. By clicking on a node, we visualize the pathways involved from the
        selected node to each molecular endpoint.
      </Typography>
      <Typography style={{ display: 'inline-block' }} sx={{ pb: 1 }}>
        When you click on a node, the Calculate button will be enabled. This button lets you access overall score for
        your molecular target along with further details on the selected target and how it can reach the different
        endpoints.
      </Typography>
      <Typography style={{ display: 'inline-block' }}>
        Using the filters on the left, you can customize how you want to visualize the disease network. First, you can
        search for a protein target if you already know its name. You can also search by using one or multiple
        annotation keywords. This will highlight the proteins that are related to any of the annotation keywords that
        are selected. You can also toggle to view/hide the relations between the proteins that are AI predicted.
        Finally, you can decide which types of node you want to display.
      </Typography>
    </Paper>
  );
}

function HelpPopper() {
  const [open, setOpen] = useState(true);
  return (
    <Container sx={{ position: 'absolute', width: 500, zIndex: 1 }}>
      <Button
        variant="outlined"
        onClick={() => {
          setOpen(!open);
        }}
      >
        {open ? 'Hide' : 'Show'} Instructions
      </Button>
      {open && <HelpText />}
    </Container>
  );
}

function GraphDiseasePage() {
  const { disease } = useParams();
  const [diseaseData, setDiseaseData] = useState({});
  const [selectedNode, setSelectedNode] = useState('');
  const location = useLocation();
  const search = location.search;
  let concFilename = new URLSearchParams(search).get('concFilename');
  if (concFilename) concFilename = decodeURIComponent(concFilename);
  const setNode = nodeStore((state) => state.setNode);

  const { getAccessTokenSilently } = useAuth0();

  async function getDiseaseDataImpl() {
    // conditional retrieve conc version in case filename is provided
    const accessToken = await getAccessTokenSilently();
    return diseaseService.getDiseaseData(disease, concFilename, accessToken);
  };
  const getDiseaseData = withServiceCallHandling(
    getDiseaseDataImpl,
    (data) => setDiseaseData(data)
  )
  // Load data from server
  useEffect(() => {
    getDiseaseData();
  }, [disease, concFilename]);

  async function calculateButtonActionGoToNodeProcessPage() {
    setNode(selectedNode.id, selectedNode.label);
    history.push(config.clientUrls.NODE_PROCESS_PATHS(disease, selectedNode.id));
  }

  async function handleGetNodeInfo(node) {
    // TODO To convert to withServiceCallHandling pattern
    const aiPredValue = AiPredStore.getState().filterValue;
    const aiPredCutoff = AiPredStore.getState().filterCutoff;
    setSelectedNode(node);
    try {
      // conditional retrieve conc version in case filename is provided
      const accessToken = await getAccessTokenSilently();
      const { paths } = await targetValidationService.getShortestPaths(
        disease,
        node.id,
        aiPredValue,
        aiPredCutoff,
        accessToken,
      );
      return paths;
    } catch (error) {
      // handle error
      console.error(error);
    }
  }

  function resetSelectedNode() {
    setSelectedNode('');
  }

  return (
    <div>
      <HelpPopper></HelpPopper>
      <Container>
        <div className="header">
          <Box sx={{ marginTop: '10px' }}>
            <Typography variant="h4" color="primary">
              Simmunome Pathway Target Analysis for {disease.charAt(0).toUpperCase() + disease.slice(1)}
            </Typography>
          </Box>
        </div>

        <SigmaGraph
          diseaseData={diseaseData}
          onGetNodeInfo={(data) => handleGetNodeInfo(data)}
          onResetEdges={() => resetSelectedNode()}
        />
        <Box>
          {selectedNode !== '' && (
            <Box>
              <p>Selected Node: {selectedNode.label}</p>
              <Button
                variant="contained"
                onClick={() => {
                  calculateButtonActionGoToNodeProcessPage();
                }}
              >
                Calculate
              </Button>
            </Box>
          )}
          {selectedNode === '' && (
            <Box>
              <p>No node selected. Please click on a target node to select it.</p>
              <Button
                variant="contained"
                disabled
                onClick={() => {
                  calculateButtonActionGoToNodeProcessPage();
                }}
              >
                Calculate
              </Button>
            </Box>
          )}
        </Box>
        <GraphLegendTable></GraphLegendTable>
      </Container>
    </div>
  );
}

export default GraphDiseasePage;
