import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '_components/ErrorFallback';
import App from './App';
import { getAuthConfig } from '_configs/getAuthConfig'
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";

// auth0
const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};
const authConfig = getAuthConfig()
const providerConfig = {
  domain: authConfig.domain,
  clientId: authConfig.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(authConfig.audience ? { audience: authConfig.audience } : null),
  },
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
    }}
  >
    <Auth0Provider
      {...providerConfig}
    >
      <App />
    </Auth0Provider>
   </ErrorBoundary>,
);
