import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import diseaseService from '_services/disease.service';
import { useAuth0 } from '@auth0/auth0-react';
import { graphTheme } from '_theme/graphTheme';

function dashed(isBroken) {
  if (isBroken) {
    return 'stroke-dasharray';
  }
  return 'stroke';
}

function backgroundColor(isBroken) {
  if (isBroken) {
    return '#fcf7f7';
  }
  return graphTheme.BACKGROUND_COLOR;
}

const DEFAULT_MARGIN = 5;

function PathGraph({ links, nodes, start, isBroken, isInteractive, dimensions = { width: 500, height: 500 } }) {
  const { getAccessTokenSilently } = useAuth0();
  const svgRef = useRef(null);
  const { width, height, margin = {} } = dimensions;
  const svgWidth = width + (margin.left || DEFAULT_MARGIN) + (margin.right || DEFAULT_MARGIN);
  const svgHeight = height + (margin.top || DEFAULT_MARGIN) + (margin.bottom || DEFAULT_MARGIN);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove(); // Clear svg content before adding new elements

    const simulation = d3
      .forceSimulation()
      .force(
        'link',
        d3
          .forceLink()
          .id((d) => d.id)
          .distance(Math.max(width, height) / 5),
      )
      .force('center', d3.forceCenter(width / 2, height / 2));

    const tooltip = d3.select('body').append('div').attr('class', 'tooltip');

    const link = svg
      .selectAll('.link')
      .data(links)
      .enter()
      .append('line')
      .style(dashed(isBroken), '3, 3')
      .attr('class', 'link')
      .attr('marker-end', 'url(#node-arrow)');

    link.append('title').text((d) => d.type);

    const edgepaths = svg
      .selectAll('.edgepath')
      .data(links)
      .enter()
      .append('path')
      .attr('class', 'edgepath')
      .attr('fill-opacity', 0)
      .attr('stroke-opacity', 0)
      .attr('id', (d, i) => `edgepath${i}`)
      .style('pointer-events', 'none');

    const node = svg
      .selectAll('.node')
      .data(nodes)
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('id', (d) => d.id)
      .on('click', async (e, d) => {
        if (!isInteractive) {
          return;
        }
        const accessToken = await getAccessTokenSilently();
        const { data } = await diseaseService.getDiseaseNodeInfo('graph_id', d.id, accessToken); // attention
        const pointX = e.pageX; // d3.event.pageX;
        const pointY = e.pageY; // d3.event.pageY;
        d3.selectAll('.tooltip').style('visibility', null).style('top', null).style('left', null);
        tooltip
          .style('visibility', 'visible')
          .style('top', `${pointY + 10}px`)
          .style('left', `${pointX + 10}px`)
          .text('STRING ID: \n')
          .style('font-weight', 'bold')
          .append('tspan')
          .text(`${data.stringId}\n\n`)
          .style('font-weight', 'normal')
          .append('tspan')
          .text('Data:\n')
          .style('font-weight', 'bold')
          .append('tspan')
          .text(`${data.taxonName}\n`)
          .style('font-weight', 'normal')
          .append('trpan')
          .text('Type: \n')
          .style('font-weight', 'bold')
          .text(`${data.annotation}\n`)
          .style('font-weight', 'normal');
      })
      .call(d3.drag().on('start', dragStart).on('drag', drag).on('end', dragEnd));

    function dragStart(event, d) {
      if (!isInteractive) {
        return;
      }
      // console.log('drag start');
      simulation.alphaTarget(0.5).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function drag(event, d) {
      // console.log('dragging');
      // simulation.alpha(0.5).restart()
      if (!isInteractive) {
        return;
      }
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragEnd(event, d) {
      // console.log('drag end');
      if (!isInteractive) {
        return;
      }
      simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    svg.on('click', () => {
      d3.selectAll('.tooltip').style('visibility', null).style('top', null).style('left', null);
    });

    function pointColor(endpoint, nid) {
      if (endpoint) {
        return graphTheme.ENDPOINT_COLOR;
      }
      if (nid === start) {
        return graphTheme.NODE_SELECTED_COLOR;
      }
      return graphTheme.NODE_IN_PATH_COLOR;
    }

    node
      .append('circle')
      .attr('r', 5)
      .style('fill', (d) => pointColor(d.is_endpoint, d.id));

    node.append('title').text((d) => d.name);

    node
      .append('text')
      .attr('dy', -3)
      .text((d) => d.name);

    function ticked() {
      link
        .attr('x1', (d) => d.source.x)
        .attr('y1', (d) => d.source.y)
        .attr('x2', (d) => d.target.x)
        .attr('y2', (d) => d.target.y);

      node.attr('transform', (d) => `translate(${d.x}, ${d.y})`);

      edgepaths.attr('d', (d) => `M ${d.source.x} ${d.source.y} L ${d.target.x} ${d.target.y}`);
    }

    simulation.nodes(nodes).on('tick', ticked);

    simulation.force('link').links(links);
  }, [links, nodes, start, isBroken]);

  return (
    <svg ref={svgRef} width={svgWidth} height={svgHeight} style={{ backgroundColor: backgroundColor(isBroken) }} />
  );
}

export default PathGraph;
