import { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { toast } from 'react-toastify';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import RowType from '_components/base/table/RowType';
import HeadCellType from '_components/base/table/HeadCellType';


interface RenamePromptProps {
    onCancel: () => void;
    onSubmit: (name: string, new_name: string) => void;
    originalName: string;
    originalNameSuffix?: string;
    originalNamePrefix?: string;
    title?: string;
    label?: string;
    renameLabel?: string;
};

export default function RenamePrompt({
    onSubmit, onCancel, originalName, originalNameSuffix = "", originalNamePrefix = "", title = "Rename Dataset", label = "Name",
    renameLabel = "Rename"
}: RenamePromptProps) {
    const theme = useTheme();
    const [name, setName] = useState<string | null | undefined>(originalName);

    async function handleRename() {
        if (name === originalName) {
            toast.error("Please, change the name first");
            return;
        }
        if (!name || name.length < 1) {
            toast.error("Name cannot be empty");
            return;
        }
        onSubmit(originalName, name)
    }

    function handleCancel() {
        onCancel();
    }

    return (
        <Box sx={{ minWidth: '300px' }}>
            <Typography variant="h5" color="primary">
                {title}
            </Typography>

            <FormControl fullWidth>
                <TextField
                    id="name-text-field"
                    label={label}
                    variant="standard"
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{originalNamePrefix}</InputAdornment>,
                        endAdornment: <InputAdornment position="end">{originalNameSuffix}</InputAdornment>,
                    }}
                />
            </FormControl>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    onClick={handleRename}
                    variant="contained"
                    component="span"
                    disabled={name === originalName}
                    sx={{ mr: 1, backgroundColor: theme.palette.primary.main }}
                >
                    {renameLabel}
                </Button>

                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleCancel} variant="contained" color="error" sx={{ mr: 1 }}>
                    Cancel
                </Button>
            </Box>
        </Box>
    );
}
