import { useEffect, useState } from 'react';
import diseaseService from '_services/disease.service';
import targetValidationService from '_services/target.validation.service';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { Container, Link, Grid, Button, Box, Divider } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import PathGraph from './PathGraph';
import Typography from '@mui/material/Typography';
import history from '_helpers/history';
import reportService from '_services/report.service';
import { saveAs } from 'file-saver';
import GraphLegendTable from '../graphDisease/graphLegendTable';
import '_css/d3.css';
import './NodeProcessPathsPage.css';
import NodeInfoAccordion from './NodeInfoAccordion';
import createPdfReport from './Report';

import AiPredStore from '_pages/targetvalidation/FilterAIPredictionStore';
import { withServiceCallHandling } from '_helpers/decorators';
import { InterruptedPath, ProteinInfo, TargetEfficacyData } from './TargetEfficacyInterface';
import Loading from '_components/Loading';

const SELECTED_GRAPH_WIDTH = 814;
const SELECTED_GRAPH_HEIGHT = 420;
const SCORES_DECIMAL = 3;

/**
 *
 * @param {{source, path: {links, nodes}, is_broken}} o
 * @param {String} key
 * @param {String} tag
 * @returns
 */
function renderPathGraph(
  o: InterruptedPath,
  key: string,
  tag: string,
  isInteractive: boolean,
  width: number = 260,
  height: number = 200,
) {
  if (o.path.links.length === 0) {
    return <Loading />;
  }
  return (
    <PathGraph
      key={key}
      links={o.path.links}
      nodes={o.path.nodes}
      start={o.path.links[0].source}
      // eslint-disable-next-line camelcase
      isBroken={false}
      isInteractive={isInteractive}
      dimensions={{
        width: width,
        height: height,
      }}
    />
  );
}

function findSelectedNodeName(nodeid: string, data: TargetEfficacyData) {
  return data?.protein_info[nodeid]?.preferredName;
}

function UnreachedEndpointsSection(unreachedEndpoints: string[], proteinInfo: ProteinInfo, selectedNodeName: string) {
  return (
    <Box>
      <div className="titlesection">
        <p>Non-interrupted paths</p>
      </div>
      <Box sx={{ margin: 2 }}>
        <Typography>Here are the unreachable endpoints from the selected target {selectedNodeName}:</Typography>
        {unreachedEndpoints?.length === 0 && (
          <Typography>All endpoints are reached by the target. There are no non-interrupted paths.</Typography>
        )}
      </Box>
      {unreachedEndpoints?.map((endpointId: string) => {
        return NodeInfoAccordion(proteinInfo[endpointId]);
      })}
    </Box>
  );
}

function NodeProcessPathsPage() {
  const { getAccessTokenSilently } = useAuth0();
  const { disease, nodeId } = useParams<{ disease: string; nodeId: string }>();
  const [selectedPath, setSelectedPath] = useState<InterruptedPath>({
    endpoint: '',
    path: { directed: true, graph: {}, links: [], multigraph: false, nodes: [] },
    score: 0,
    target: '',
  });
  const [efficacyData, setEfficacyData] = useState<TargetEfficacyData>({
    interrupted_paths: [],
    protein_info: {},
    report_id: '',
    score: 0,
    unreached_endpoints: [],
  });
  const [selectedNodeName, setSelectedNodeName] = useState('');
  const aiPredValue = AiPredStore.getState().filterValue;
  const aiPredCutoff = AiPredStore.getState().filterCutoff;
  const aiPredIsOn = AiPredStore.getState().filterBooleanValue;

  function displaySelectedPath() {
    return renderPathGraph(selectedPath, 'main', 'main', true, SELECTED_GRAPH_WIDTH, SELECTED_GRAPH_HEIGHT);
  }

  function handleSetSelectedPath(pathId: number) {
    setSelectedPath(efficacyData.interrupted_paths[pathId]);
  }

  async function getDataImpl() {
    const accessToken = await getAccessTokenSilently();
    return targetValidationService.getScores(disease, nodeId, aiPredValue, aiPredCutoff, accessToken);
  }
  const fetchData = withServiceCallHandling(getDataImpl, (data: any) => {
    setEfficacyData(data);
    setSelectedNodeName(findSelectedNodeName(nodeId, data));

    if (data.interrupted_paths.length > 0) {
      setSelectedPath(data.interrupted_paths[0]);
    }
  });

  useEffect(() => {
    fetchData();
  }, [disease, nodeId]);

  async function getReportImpl(reportId: string) {
    const accessToken = await getAccessTokenSilently();
    return reportService.getReport(reportId, accessToken);
  }
  const handleRequestReport = withServiceCallHandling(getReportImpl, (data, blob) => {
    saveAs(blob, 'report.csv');
  });

  const gridItems = [];
  const interruptedEndpoints: string[] = [];
  for (let i = 0; i < efficacyData?.interrupted_paths?.length; i++) {
    const endpointId = efficacyData?.interrupted_paths[i]?.endpoint;
    const individualScore = efficacyData?.interrupted_paths[i]?.score;
    const endpointName = efficacyData?.protein_info[endpointId]?.preferredName;
    interruptedEndpoints.push(endpointName);
    gridItems.push(
      <ListItem disablePadding className="smallPathContainer" key={i}>
        <ListItemButton onClick={() => handleSetSelectedPath(i)}>
          <div>
            <Grid key={`grid-cell-complete-${i}`} item xs={100} style={{ textAlign: 'left' }}>
              <div>
                <Typography>Path - {endpointName}</Typography>
                <Typography>Score: {individualScore.toFixed(SCORES_DECIMAL)}</Typography>
              </div>
              <div className="smallPathGraphContainer">
                {renderPathGraph(
                  efficacyData.interrupted_paths[i],
                  `not-broken-p-${i}`,
                  '#not-broken',
                  false,
                  260,
                  200,
                )}
              </div>
            </Grid>
          </div>
        </ListItemButton>
      </ListItem>,
    );
  }

  return (
    <Container disableGutters sx={{ maxWidth: '100%', magin: 'auto' }}>
      <div className="titlesection">
        <p>
          INTERRUPTED PATHS FOR TARGET {selectedNodeName} {'('}
          {disease}
          {')'}
        </p>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="interPathsContainer roundedBorderContainer">
          <Typography variant="h6">Overall score: {efficacyData?.score.toFixed(SCORES_DECIMAL)}</Typography>
          <Divider sx={{ mb: 1 }}></Divider>
          <div className="pathsListContainer">
            <Grid container direction="row" justifyContent="space-between" alignItems="center" width={300}>
              {gridItems}
            </Grid>
          </div>
        </div>
        <Box className="selectedPathContainer roundedBorderContainer">
          <Typography align="center">{efficacyData?.protein_info[selectedPath.endpoint]?.preferredName}</Typography>
          <Box sx={{ border: 1, borderColor: '#DADADA', padding: 0 }}>{displaySelectedPath()}</Box>

          <GraphLegendTable></GraphLegendTable>
        </Box>
      </div>
      <Typography>Target:</Typography>
      {NodeInfoAccordion(efficacyData?.protein_info[nodeId])}
      <Typography>Endpoints:</Typography>
      {efficacyData?.interrupted_paths.map((e) => (
        <div key={e.endpoint}>{NodeInfoAccordion(efficacyData?.protein_info[e.endpoint])}</div>
      ))}
      {UnreachedEndpointsSection(efficacyData?.unreached_endpoints, efficacyData?.protein_info, selectedNodeName)}

      <div className="titlesection">
        <p>Summary</p>
      </div>
      <div>
        <Box sx={{ margin: 2 }}>
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h6"> Target name: {selectedNodeName}</Typography>
            <Typography variant="h6"> Target Score: {efficacyData?.score.toFixed(SCORES_DECIMAL)}</Typography>
            <Typography sx={{ mt: 1 }}> String ID: {nodeId}</Typography>
            <Typography> Disease: {disease}</Typography>
            <Typography>{`Interrupted endpoints: ${interruptedEndpoints.join(',')}.`}</Typography>
            <Typography>AI predictions: {aiPredIsOn ? `ON with cutoff = ${aiPredCutoff}` : 'OFF'}</Typography>
          </Box>
          <Typography>
            Find out more about the selected target:{' '}
            <Link target="_blank" href={`https://string-db.org/network/${nodeId}`}>
              {selectedNodeName}
            </Link>
          </Typography>
        </Box>
        <img
          src={`https://string-db.org/api/image/network?identifiers=${selectedNodeName}&add_color_nodes=8&network_type=physical" alt="alternatetext`}
        />
        <div style={{ display: 'flex' }}>
          <p></p>
          <Box style={{ flexGrow: 1 }}></Box>
          <Button variant="contained" onClick={() => handleRequestReport(efficacyData?.report_id)}>
            Download pathways CSV
          </Button>
        </div>

        <p></p>
        <div style={{ display: 'flex' }}>
          <Box style={{ flexGrow: 1 }}></Box>
          <Button variant="contained" onClick={() => handleRequestReport(efficacyData?.report_id)}>
            Download statistics CSV
          </Button>
        </div>
        <p></p>
        <div style={{ display: 'flex' }}>
          <Button variant="outlined" onClick={() => history.goBack()}>
            Back to source network
          </Button>
          <Box style={{ flexGrow: 1 }}></Box>
          <Button
            variant="contained"
            onClick={() => {
              createPdfReport(
                selectedNodeName,
                nodeId,
                disease,
                efficacyData.interrupted_paths,
                interruptedEndpoints,
                efficacyData.score.toFixed(SCORES_DECIMAL),
                efficacyData.protein_info,
                efficacyData.unreached_endpoints,
              );
            }}
          >
            Download PDF report
          </Button>
        </div>
      </div>

      <p></p>
    </Container>
  );
}

export default NodeProcessPathsPage;
