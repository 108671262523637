import { useState, useMemo, ReactNode, ComponentType, ReactElement } from 'react';
import Table, { TableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import EnhancedTableHead, { Order } from './EnhancedTableHead';
import { SxProps, Theme } from '@mui/material';
import HeadCellType from './HeadCellType';
import moment from 'moment';

import '_css/home.css';

import RowType, { RowElementType } from './RowType';
import { renderRowElement } from './RowTypeAPI';
import CellDate from '../CellDate';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any, T>(
    order: Order,
    // orderBy: Key,
    orderBy: keyof T,
): (
    // a: { [key in Key]: number | string | boolean | string[] | undefined | ReactElement },
    // b: { [key in Key]: number | string | boolean | string[] | undefined | ReactElement },
    a: T,
    b: T,
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export type CustomTableToolbarComponentType = ComponentType<{
    selected: string[];
    permanentToolbarIcons?: ReactNode;
    selectedToolbarIcons?: ReactNode;
}>;

const defaultTableSx: SxProps<Theme> | undefined = {
    minWidth: 750,
};

export interface EnhancedTableProps {
    headCells: HeadCellType[];
    idHeadCell: HeadCellType;
    rows: RowType[];
    selected: RowType[];
    setSelected: (val: RowType[]) => void;
    allowMultipleSelect?: boolean;
    allowSelect?: boolean;
    tableSx?: SxProps<Theme>;
    tableBodyRowSx?: SxProps<Theme>;
    tableContainerRowSx?: SxProps<Theme>;
    tableHeadSx?: SxProps<Theme>;
    tableHeadRowSx?: SxProps<Theme>;
    tableHeadCellSx?: SxProps<Theme>;
    forcePagination?: boolean;
    tableProps?: TableProps;
}

export default function EnhancedTable({
    headCells,
    rows,
    selected,
    setSelected,
    allowSelect = true,
    allowMultipleSelect = true,
    tableSx = defaultTableSx,
    tableHeadSx,
    tableHeadRowSx,
    tableHeadCellSx,
    tableBodyRowSx,
    tableContainerRowSx,
    forcePagination = false,
    tableProps,
    idHeadCell,
}: EnhancedTableProps) {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>(idHeadCell.id);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string /*keyof Data*/) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            // const newSelected: RowElementType[] = rows.map((row) => row[idHeadCell.id]);
            // setSelected(newSelected);
            setSelected(rows.slice());
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, clickedRow: RowType) => {
        if (!allowSelect) return;
        const selectedIndex = selected.findIndex((r) => r[idHeadCell.id] === clickedRow[idHeadCell.id]);
        if (!allowMultipleSelect) {
            setSelected(selectedIndex === -1 ? [clickedRow] : []);
            return;
        }
        let newSelected: RowType[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, clickedRow);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (rowElement: RowType) =>
        selected.findIndex((r) => r[idHeadCell.id] === rowElement[idHeadCell.id]) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [order, orderBy, page, rowsPerPage, rows],
    );
    const isPaginationActive = forcePagination || rows.length >= 10;

    return (
        <>
            <TableContainer sx={tableContainerRowSx}>
                <Table sx={tableSx} aria-labelledby="tableTitle" {...tableProps}>
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        headCells={headCells}
                        allowMultipleSelect={allowMultipleSelect}
                        tableHeadSx={tableHeadSx}
                        tableHeadRowSx={tableHeadRowSx}
                        tableHeadCellSx={tableHeadCellSx}
                        allowSelect={allowSelect}
                    />
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            const isItemSelected = isSelected(row);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row[idHeadCell.id] as string}
                                    selected={isItemSelected}
                                    sx={tableBodyRowSx || { cursor: 'pointer' }}
                                >
                                    {allowSelect && (
                                        <TableCell
                                            padding="checkbox"
                                            onClick={(event: React.MouseEvent<HTMLElement>) => handleClick(event, row)}
                                        >
                                            {row.selectable !== false && (
                                                <Checkbox
                                                    id={`body-checkbox-${row[idHeadCell.id]}`}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            )}
                                        </TableCell>
                                    )}
                                    {headCells.map(
                                        ({
                                            id,
                                            isDate,
                                            isSelfClickable,
                                            rowCellSx,
                                            alignRowCell,
                                            renderElementImpl,
                                        }) => {
                                            let el = row[id];
                                            if (renderElementImpl) el = renderElementImpl(el, row);
                                            else if (isDate) el = <CellDate value={el} />;
                                            return (
                                                <TableCell
                                                    key={id}
                                                    align={alignRowCell || 'inherit'}
                                                    id={id === 'name' ? labelId : `tablecell-${id}`}
                                                    scope={id === 'name' ? 'row' : undefined}
                                                    padding={id === 'name' ? 'none' : undefined}
                                                    // header must declare that element is self clickable instead of row clickable
                                                    onClick={
                                                        isSelfClickable
                                                            ? undefined
                                                            : (event: React.MouseEvent<HTMLElement>) =>
                                                                  handleClick(event, row)
                                                    }
                                                    sx={rowCellSx}
                                                >
                                                    {renderRowElement(el)}
                                                </TableCell>
                                            );
                                        },
                                    )}
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 33 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {isPaginationActive && (
                <TablePagination
                    rowsPerPageOptions={[5, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </>
    );
}
