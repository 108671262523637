import { Container, Typography, Box, TextField, Paper, Button } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useState } from 'react';
import { toast } from 'react-toastify';

interface QuestionEntryProps {
  question: string;
  answer: string;
}

function QuestionEntryPros({ question, answer }: QuestionEntryProps) {
  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography variant="h6">{question}</Typography>
      <Typography>{answer}</Typography>
    </Box>
  );
}

function Report() {
  const SIDE_MARGIN = 30;
  const [messageType, setMessageType] = useState<string>('question');
  const [message, setMessage] = useState<string>('');

  function handleSendClick() {
    toast.success('Message sent!', { autoClose: 1000 });
    setMessage('');
  }

  return (
    <Box sx={{ marginLeft: SIDE_MARGIN, marginRight: SIDE_MARGIN }}>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <TextField
          fullWidth
          label="Write a message"
          multiline
          rows={10}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <FormControl>
            <FormLabel>Message type</FormLabel>
            <RadioGroup row value={messageType} onChange={(event) => setMessageType(event.target.value)}>
              <FormControlLabel value="question" control={<Radio />} label="Question" />
              <FormControlLabel value="bug" control={<Radio />} label="Bug Report" />
              <FormControlLabel value="other" control={<Radio />} label="Other" />
            </RadioGroup>
          </FormControl>
          <Button
            disabled={message === ''}
            variant="contained"
            sx={{ margin: 'auto', marginRight: 0 }}
            onClick={() => handleSendClick()}
          >
            Send
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default function Help() {
  const faq = [
    {
      question: 'How do I upload my files?',
      answer:
        "Go to My Data, and click on the '+' button. Make sure your data is in the correct format, and follow the instructions.",
    },
    {
      question: 'How do I change my password?',
      answer:
        'Logout, and click on login. If you click on the forgot password button, you should be able to change your password.',
    },
    {
      question: 'How do I upgrade my tier/ get more credits?',
      answer:
        'You can send an email to your Simmunome contact, or to support@simmunome.com. You can also go to the Account page.',
    },
  ];
  return (
    <Container>
      <div className="header">
        <Box sx={{ marginTop: '10px', marginBottom: '10px' }}>
          <Typography variant="h4" color="primary">
            FAQ
          </Typography>
        </Box>
      </div>
      {faq.map((qa, index) => (
        <QuestionEntryPros key={index} question={qa.question} answer={qa.answer} />
      ))}
      <div className="header">
        <Box sx={{ marginTop: '30px', marginBottom: '10px' }}>
          <Typography variant="h5" color="primary">
            {"Can't find an answer? Ask us!"}
          </Typography>
        </Box>
      </div>
      <Report></Report>
    </Container>
  );
}
