import useStore from "_helpers/store";
import SSEListener from "./SSEListener";

export default function SSEHub() {
    const sseChannels = useStore((state) => state.sse_channels);
    return sseChannels.map(ch => (
        <SSEListener
            channelId={ch.id}
            key={ch.id}
            url={ch.url}
            failCallback={ch.failCallback}
            successCallback={ch.successCallback}
        />
    ));
}
