import { useSigma, useRegisterEvents } from '@react-sigma/core';
import { useEffect } from 'react';
import { highlightPath, resetAllEdgesColor, leaveAllNodes } from '_helpers/sigmaEvents';

/**
 * @param {setHoveredNode: (node: string | null) => void} param0
 * @returns
 */
function GraphEventsController({ setHoveredNode, onGetNodeInfo, children, onResetEdges }) {
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const registerEvents = useRegisterEvents();

  /**
   * Initialize here settings that require to know the graph and/or the sigma
   * instance:
   */
  useEffect(() => {
    registerEvents({
      clickNode: async ({ node }) => {
        resetAllEdgesColor(sigma);
        leaveAllNodes(sigma);
        const node_label = graph.getNodeAttribute(node, 'label');
        if (!graph.getNodeAttribute(node, 'hidden')) {
          const paths = await onGetNodeInfo({ id: node, label: node_label });
          paths.forEach((path) => {
            highlightPath(sigma, path);
          });
        }
      },
      enterNode: ({ node }) => {
        setHoveredNode(node);
      },
      leaveNode: () => {
        setHoveredNode(null);
      },
      clickStage: () => {
        resetAllEdgesColor(sigma);
        leaveAllNodes(sigma);
        onResetEdges();
      },
    });
  }, []);

  return children;
}

export default GraphEventsController;
