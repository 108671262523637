import { toast } from 'react-toastify';

export function withServiceCallHandling(
    target: any,
    successCallback?: (data: any, response: any) => any,
    errorCallback?: () => void,
    loading?: boolean | string,
    preFunc?: () => boolean, // logic to exit function in advance
) {
    const originalMethod = target;
    return async function (...args: any[]) {
        if (preFunc && !preFunc()) return;
        let toastId;
        if (loading) {
            toastId = toast.loading(typeof loading === "string" ? loading : "Processing...");
        }
        try {
            const response = await originalMethod.apply(this, args);
            const { data, message } = response;
            if (toastId) {
                toast.update(toastId, {
                    type: toast.TYPE.SUCCESS,
                    autoClose: 2000,
                    render: message || "Complete",
                    isLoading: false
                });
            } else if (message) {
                toast.success(message);
            }
            let successReturnVal;
            if (successCallback) successReturnVal = successCallback(data, response);
            return successReturnVal || data || response;
        } catch (error: any) {
            console.error(error);
            const errorMessage = error.message || "An error occurred";
            if (toastId) {
                toast.update(toastId, {
                    type: toast.TYPE.ERROR,
                    autoClose: false,
                    render: errorMessage,
                    isLoading: false
                });
            } else {
                toast.error(errorMessage);
            }
            if (errorCallback) errorCallback();
            return false;
        }
    };
}