import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { graphTheme } from '_theme/graphTheme';

export default function GraphLegendTable() {
  const tableStyles = {
    margin: 'auto',
    width: '100%',
  };
  const tableCellStyles = {
    textAlign: 'left',
    padding: '8px',
  };
  return (
    <>
      <Table className="Legend" style={tableStyles}>
        <TableHead>
          <TableRow>
            <TableCell sx={tableCellStyles}>NODE TYPE</TableCell>

            <TableCell sx={tableCellStyles}>DESCRIPTION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCellStyles}>
              <span style={{ color: graphTheme.NODE_SELECTED_COLOR }}>Target</span>
            </TableCell>
            <TableCell sx={tableCellStyles}>The selected molecular node</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCellStyles}>
              <span style={{ color: graphTheme.NODE_IN_PATH_COLOR }}>Intermediate</span>
            </TableCell>
            <TableCell sx={tableCellStyles}>Node inside the pathway</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCellStyles}>
              <span style={{ color: graphTheme.ENDPOINT_COLOR }}>Endpoint</span>
            </TableCell>
            <TableCell sx={tableCellStyles}>The disease endpoint</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}
