import { useAuth0 } from '@auth0/auth0-react';
import { useIsMounted } from '_hooks/useIsMounted';
import projectsService from '_services/harbor/projects.service';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PlotsSSFromProject from '_components/plots/PlotsSSFromProject';
import InternalFileObjType from '_components/base/table/InternalFileObj';
import SaveProjectModal from '_pages/Projects/routines/SaveProjectModal';
import { Box, Button, Chip, Container, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { withServiceCallHandling } from '_helpers/decorators';

export interface UploadType {
    disease: string;
    files: InternalFileObjType[];
    [key: string]: string | InternalFileObjType[];
}

export interface VisProjectType {
    name: string;
    is_default: boolean;
    upload: UploadType;
    group_column: string;
    group_values: string[];
    [key: string]: string | string[] | UploadType | boolean;
}

export default function VisProject() {
    const isMounted = useIsMounted();
    const { getAccessTokenSilently } = useAuth0();
    const [projectData, setProjectData] = useState<VisProjectType>();
    const { projectId } = useParams<any>();
    const [openModal, setOpenModal] = useState<boolean>(false);

    useEffect(() => {
        getVisProjectInfo();
    }, []);

    async function saveProjectImpl(name: string) {
        const accessToken = await getAccessTokenSilently();
        return projectsService.saveProject(accessToken, projectId, name);
    }
    const handleSaveProject = withServiceCallHandling(
        saveProjectImpl,
        (data) => {
            setProjectData({ ...projectData, ...data }),
                setOpenModal(false);
        }
    );

    async function getVisProjectImpl() {
        const accessToken = await getAccessTokenSilently();
        return projectsService.getVisProject(accessToken, projectId)
    }
    const getVisProjectInfo = withServiceCallHandling(
        getVisProjectImpl,
        (data) => { setProjectData(data) },
        undefined, false,
        () => isMounted()
    )

    if (!projectData) return <></>
    return (<Container>
        <SaveProjectModal
            open={openModal}
            onCancel={() => { setOpenModal(false) }}
            onSubmit={handleSaveProject}
        />
        <Stack spacing={2}>
            <Box className="header">
                <Typography variant="h4" color="primary">
                    Visualization
                </Typography>
            </Box>
            <Box sx={{ marginTop: '10px' }} display="block">

                {!projectData.is_default && (
                    <Typography variant="h4" color="primary">
                        Project: {projectData.name}
                    </Typography>
                )}

                <Typography variant="body1" color="primary" fontStyle="italic" align='left'>
                    Files:
                </Typography>
                <Stack direction="row" justifyContent="start">
                    {projectData.upload.files.map((f, indx) => <Chip key={`chip-${indx}`} label={f._id} size="medium" />)}
                </Stack>


                <Typography variant="body1" color="primary" fontStyle="italic" align='left'>
                    Group column: {projectData.group_column}
                </Typography>
                <Typography variant="body1" color="primary" fontStyle="italic" align='left'>
                    Group values: {projectData.group_values.join(', ')}
                </Typography>

                {projectData.is_default && (
                    <Button variant='outlined' onClick={() => { setOpenModal(true) }}>Save Project</Button>
                )}
            </Box>

            <PlotsSSFromProject projectId={projectId} />
        </Stack>
    </Container>);
}