import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { InterruptedPath, ProteinInfo } from './TargetEfficacyInterface';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
var testMode = false; // if true just opens the pdf instead of downloading it

function findScoreOfEndpoint(endpointId: string, indiScores: any): number {
  for (var i = 0; i < indiScores.length; i++) {
    const s = indiScores[i];
    if (s.endpoint === endpointId) {
      return s.pathscore;
    }
  }
  return 0;
}

function InterruptedPaths(interruptedPaths: InterruptedPath[], endpointsNames: string[]): Content {
  var contents: Content[] = [
    {
      text: 'Interrupted Paths',
      style: 'sectionTitle',
      margin: [0, 10, 0, 0],
    },
  ];
  var currentKey = 0;
  interruptedPaths.forEach((p: InterruptedPath) => {
    const targetName = p.target;
    const endpointName = endpointsNames[currentKey];
    const endpointId = p.endpoint;
    const nNodesInPath = p.path.nodes.length;
    const score = findScoreOfEndpoint(endpointId, p.score);
    contents.push(createPathSection(currentKey + 1, targetName, endpointName, endpointId, nNodesInPath, score));
    currentKey++;
  });

  return contents;
}

function NonInterruptedPaths(unreachedEndpoints: string[], targetName: string, proteinInfo: ProteinInfo): Content {
  var contents: Content[] = [
    {
      text: 'Non-Interrupted Paths',
      style: 'sectionTitle',
      margin: [0, 10, 0, 0],
    },
  ];
  var currentKey = 0;
  unreachedEndpoints.forEach((endpointId: string) => {
    currentKey++;
    const endpointName = proteinInfo[endpointId].preferredName;
    const nNodesInPath = -10;
    const score = 0;
    contents.push(createPathSection(currentKey, targetName, endpointName, endpointId, nNodesInPath, score, false));
  });

  return contents;
}

function createPathSection(
  pathKey: number,
  targetName: string,
  endpointName: string,
  endpointId: string,
  numberOfNodeInPath: number,
  score: number,
  showNumberOfNodeInPath: boolean = true,
): Content {
  var content: Content = [
    {
      text: `${targetName}->${endpointName} Path${pathKey} Information:\n`,
      margin: [4, 10, 4, 4],
      bold: true,
      style: 'nodeInformation',
    },
    {
      table: {
        headerRows: 0,
        body: [
          [{ text: 'Endpoint Name:', alignment: 'right' }, endpointName],
          [{ text: 'String ID:', alignment: 'right' }, endpointId],
          ...(showNumberOfNodeInPath ? [[{ text: 'Path Length:', alignment: 'right' }, numberOfNodeInPath]] : []),
          [
            { text: 'Individual Score:', bold: true, alignment: 'right' },
            { text: score, bold: true },
          ],
        ],
      },
      layout: 'noBorders',
      style: 'nodeInformation',
    },
  ];

  return content;
}

function Separator(x_pos: number = -230): Content {
  return {
    canvas: [
      {
        type: 'rect',
        x: x_pos,
        y: 1,
        w: 220,
        h: 10,
        r: 7,
        color: '#FBD46C',
      },
    ],
  };
}

function createPdfReport(
  targetName: string,
  targetId: string,
  diseaseName: string,
  interruptedPaths: InterruptedPath[],
  interruptedEndpointsNames: string[],
  score: string,
  protein_info: ProteinInfo,
  unreachedEndpoints: string[],
) {
  const docDefinition: TDocumentDefinitions = {
    content: [
      Separator(440),
      {
        text: 'Simmunome Report for Target ' + targetName,
        style: 'header',
      },
      {
        canvas: [
          {
            type: 'rect',
            x: -100,
            y: 1,
            w: 620,
            h: 10,
            r: 7,
            color: '#113046',
          },
        ],
      },
      {
        text: [
          {
            text: `This is an automated report on the ${targetName} target made by Simmunome's target validation AI.\n\n`,
            style: { fontSize: 9 },
          },
          {
            text: `How to interpret the scores:`,
          },
        ],
        style: {
          fontSize: 11,
        },
        margin: [0, 5, 0, 0],
      },
      {
        // to treat a paragraph as a bulleted list, set an array of items under the ul key
        ul: [
          'The scores for individual endpoints are numbers ranging from 0 to 1.',
          `The target score gives an idea of the probability of success of the Target for the ${diseaseName} disease.`,
          `Any individual score below 1 is an idea of the probability of interaction predicted by Simmunome's AI model.`,
          `An individual score of 1 means that the interaction the target has on an endpoint is already experimentally confirmed.`,
        ],
        style: { fontSize: 10 },
      },

      Separator(),
      { text: 'Target', style: 'sectionTitle' },
      {
        text: 'Target Information:\n',
        margin: [4, 10, 4, 4],
        bold: true,
        style: 'nodeInformation',
      },
      {
        table: {
          headerRows: 0,
          body: [
            [{ text: 'Name:', alignment: 'right' }, targetName],
            [{ text: 'String ID:', alignment: 'right' }, targetId],
            [{ text: 'Disease:', alignment: 'right' }, diseaseName],
            [
              { text: 'Target Score:', bold: true, alignment: 'right' },
              { text: score, bold: true },
            ],
          ],
        },
        layout: 'noBorders',
        style: 'nodeInformation',
      },

      Separator(),
      InterruptedPaths(interruptedPaths, interruptedEndpointsNames),
      Separator(),
      NonInterruptedPaths(unreachedEndpoints, targetName, protein_info),
    ],

    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 5],
      },
      nodeInformation: { margin: 4, fontSize: 10 },
      sectionTitle: {
        fontSize: 14,
        bold: true,
      },
    },
  };

  if (testMode) {
    pdfMake.createPdf(docDefinition).open();
  } else {
    pdfMake.createPdf(docDefinition).download('SimmunomeTargetReport_' + targetName + '.pdf');
  }
}

export default createPdfReport;
