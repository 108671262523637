/**
 * FileUploads.tsx is a specific case when generalizing 
 *  _components/base/table/ContentTable.tsx further could
 *  make two large non generalizable components
 */
import { useState, ReactNode, ComponentType, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAuth0 } from '@auth0/auth0-react';
import { Chip, Container, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { DataObject, Timeline } from '@mui/icons-material';
import { toast } from 'react-toastify';
import RowType, { RowElementType } from '_components/base/table/RowType';
import HeadCellType from '_components/base/table/HeadCellType';
import BasicModal from '_components/base/BasicModal';
import UploadDatasetPrompt, { OnSubmitOptions } from '_components/content/UploadDatasetPrompt';
import EnhancedTableToolbar from '_components/base/table/EnhancedTableToolbar';
import EnhancedTable from '_components/base/table/EnhancedTable';
import { isInternalFileObjArrayType } from "_components/base/table/InternalFileObjAPI";
import InternalFileObjType from '_components/base/table/InternalFileObj';
import { isInternalIdNameObjArrayType } from '_components/base/table/InternalIdNameObjAPI';
import RenamePrompt from '_components/content/RenamePrompt';
import fileService from '_services/files.service';
import { humanReadableSize } from '_helpers/data';
import useStore from '_helpers/store';
import { useIsMounted } from '_hooks/useIsMounted';
import PageHeader from '_components/base/PageHeader';
import { withServiceCallHandling } from '_helpers/decorators';

const styles = {
    uploadModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    },
    optionsModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    }
}

type OptionKindsType = {
    [key: string]: string;
}

// Add new option names here 
const OPTION_KINDS: OptionKindsType = {
    rename: 'rename',
}

export type FileUploadsToolbarComponentType = ComponentType<{
    selected: string[];
    permanentToolbarIcons?: ReactNode;
    selectedToolbarIcons?: ReactNode;
}>;

export interface FileUploadsProps {
    asPage?: boolean;
    idHeadCell?: HeadCellType;
    headCells?: HeadCellType[];
    onRowsSelected?: (rows: RowType[]) => void;
    tableTitle?: string;
    allowInsert?: boolean;
    allowDelete?: boolean;
    allowFilter?: boolean;
    allowRename?: boolean;
    allowMultipleSelect?: boolean;
    initRows?: RowType[];
    initRowsSelected?: RowType[];
}

const DEFAULT_ID_HEAD_CELL: HeadCellType = {
    id: '_id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
}

const DEFAULT_HEAD_CELLS: HeadCellType[] = [
    {
        id: 'files',
        numeric: false,
        disablePadding: false,
        label: 'Name',
        complex: true,
        renderElementImpl: (files: RowElementType) => {
            /* content: [{_id, type, src}]*/
            if (!isInternalFileObjArrayType(files)) return undefined;
            return (
                <Tooltip title={
                    <Box flexDirection="column">
                        {files.map(f => (
                            <Box key={f._id} display="flex" gap={2}>
                                {f.type === "quantitative" ? < DataObject /> : <Timeline />}
                                <Typography variant="body2">{f._id}</Typography>
                            </Box>
                        ))}
                    </Box>
                }
                >
                    <Typography variant="body2">{(files[0]._id as string).split(".")[0]}</Typography>
                </Tooltip>
            );

        }
    },
    {
        id: 'size',
        numeric: true,
        disablePadding: false,
        label: 'Size',
        renderElementImpl: (value: RowElementType) => {
            if (typeof value !== 'number') return undefined;
            return humanReadableSize(value);
        },
        alignRowCell: 'right',
        rowCellSx: { minWidth: '100px' }
    },
    {
        id: 'disease',
        numeric: true,
        disablePadding: false,
        label: 'Disease',
        alignRowCell: 'right',
    },
    {
        id: 'modality',
        numeric: true,
        disablePadding: false,
        label: 'Modality',
        alignRowCell: 'right',
    },
    {
        id: 'platform',
        numeric: true,
        disablePadding: false,
        label: 'Platform',
        alignRowCell: 'right',
    },
    {
        id: 'created_at',
        numeric: true,
        disablePadding: false,
        isDate: true,
        label: 'Date',
        alignRowCell: 'right',
        rowCellSx: { minWidth: '110px' }
    },
    {
        id: 'processed_files',
        numeric: true,
        disablePadding: false,
        alignRowCell: 'right',
        label: 'Status',
        renderElementImpl: (processed_files: RowElementType) => {
            if (!isInternalFileObjArrayType(processed_files)) return undefined;
            if (processed_files.length < 1) return "UPLOADED";
            if (processed_files.length === 1) return "PROCESSED";
            if (processed_files.length === 2) return "Custom PROCESSED ";
            return
        }
    },
    {
        id: 'models',
        numeric: false,
        disablePadding: false,
        label: 'Used by Models',
        renderElementImpl: (models: RowElementType) => {
            if (!isInternalIdNameObjArrayType(models)) return undefined;
            return (<Stack direction="column" spacing={0.1}>
                {models.map(m => (
                    <Chip key={m._id} label={m.name} size="small" />
                ))}
            </Stack>);
        },
    },
];

type OptionsModalType = {
    open: boolean;
    kind: string | null;
}

interface RowFilter {
    columnId: string;
    value: string;
}

export default function FileUploads({
    asPage = true,
    headCells = DEFAULT_HEAD_CELLS,
    idHeadCell = DEFAULT_ID_HEAD_CELL,
    tableTitle = 'Data File Uploads',
    onRowsSelected,
    allowInsert = true,
    allowDelete = true,
    allowFilter = true,
    allowRename = true,
    allowMultipleSelect = true,
    initRows = [],
    initRowsSelected = [],
}: FileUploadsProps) {
    const isMounted = useIsMounted();
    const [rows, setRows] = useState<RowType[]>(initRows);

    const [selected, setSelected] = useState<RowType[]>(initRowsSelected);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedDropdownRow, setSelectedDropdownRow] = useState<RowType | null>(null);
    const [rowFilters, setRowFilters] = useState<RowFilter[]>([]);

    const { getAccessTokenSilently } = useAuth0();
    const [uploadModal, setUploadModal] = useState({ open: false });
    const [optionsModal, setOptionsModal] = useState<OptionsModalType>({
        open: false,
        kind: null,
    });

    async function listFileUploadsImpl() {
        if (!isMounted()) return;
        const accessToken = await getAccessTokenSilently();
        return fileService.listFileUploads(accessToken);
    }
    const listUploads = withServiceCallHandling(
        listFileUploadsImpl,
        (data) => setRows(data),
    );

    useEffect(() => {
        if (!rows || rows.length < 1)
            listUploads();
    }, [])

    function setSelectedImpl(selectedRows: RowType[]) {
        setSelected(selectedRows);
        if (onRowsSelected) onRowsSelected(selectedRows);
    }


    function addRows(rowsToAdd: RowType[]) {
        const rowsToAddNames: RowElementType[] = rowsToAdd.map((row) => row[idHeadCell.id])
        const newRows = rows.filter((row) => !rowsToAddNames.includes(row[idHeadCell.id]));
        setRows(newRows.concat(rowsToAdd));
    }

    function removeRows(selected: RowType[]) {
        const newRows = rows.filter(row => !selected.map(row => row[idHeadCell.id])
            .includes(row[idHeadCell.id]))
        setRows(newRows);
    }


    const addSSEChannel = useStore((state) => state.add_channel);

    const handleOpen = () => setUploadModal({ open: true });
    const handleClose = () => {
        setUploadModal({ open: false });
    };
    const handleCloseOptionModal = () => {
        setOptionsModal({ open: false, kind: null });
        handleCloseOptionsDropdown();
    };

    async function uploadFilesImpl(files: File[], { disease, modality, platform }: OnSubmitOptions) {
        const accessToken = await getAccessTokenSilently();
        return fileService.uploadFiles(files, { disease, modality, platform }, accessToken);
    }
    const handleFilesUpload = withServiceCallHandling(
        uploadFilesImpl,
        (data, response) => {
            handleClose();
            const { sse_channel } = response;
            const failCallback = listUploads;
            const successCallback = listUploads;
            addSSEChannel(sse_channel, fileService.URLS.sseUploadFiles, failCallback, successCallback);
            addRows([response.record] as RowType[]);
        },
        undefined,
        "Uploading file...",
        () => {
            if (allowInsert) return true;
            toast.error("Add functionality is not allowed", { autoClose: 2000 });
            return false;
        }
    )
    async function renameFilePrefixImpl(upload_id: RowElementType, prefix: string, newPrefix: string) {
        const accessToken = await getAccessTokenSilently();
        return fileService.renameFilePrefix(upload_id, prefix, newPrefix, accessToken);
    }
    const handleFileRename = withServiceCallHandling(
        renameFilePrefixImpl,
        (data) => {
            const upload_id = data.upload_id;
            handleCloseOptionModal();
            const newRows: RowType[] = rows.map((row) => {
                if (row._id !== upload_id) return row;
                return { ...row, files: data?.files, processed_files: data?.processed_files };
            })
            setRows(newRows);
        },
        undefined,
        "Renaming...",
        () => {
            if (allowRename) return true;
            toast.error("Rename functionality is not allowed", { autoClose: 2000 })
            return false;
        }
    )

    async function deleteFileUploadsImpl() {
        const accessToken = await getAccessTokenSilently();
        const ids = selected.map(r => r[idHeadCell.id]);
        return fileService.deleteFileUploads(ids, accessToken);
    }
    const handleDeleteSelected = withServiceCallHandling(
        deleteFileUploadsImpl,
        (data, response) => {
            removeRows(selected);
            setSelected([]);
        },
        undefined,
        false,
        () => {
            if (!allowDelete) {
                toast.error("Delete functionality is not allowed", { autoClose: 2000 });
                return false;
            } else if (!selected || selected.length < 1) return false;
            return true;
        }
    )

    const permanentToolbarIcons: ReactNode = (
        <>
            {allowInsert && (<div>
                <Tooltip title="Upload a new data file">
                    <IconButton onClick={handleOpen}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>

                <BasicModal
                    open={uploadModal.open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    boxSx={styles.uploadModal}
                >
                    <UploadDatasetPrompt onCancel={handleClose} onSubmit={handleFilesUpload} />
                </BasicModal>
            </div>)
            }
        </>
    );
    const selectedToolbarIcons: ReactNode = (
        <>
            {allowDelete && (<div>
                <Tooltip title="Delete">
                    <IconButton onClick={handleDeleteSelected}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </div >)
            }
        </>
    );

    const optionsHeadCell: HeadCellType = {
        isEmpty: true,
        // to satisfy type requirements for HeadCellType
        id: 'optionsDropdown',
        numeric: false,
        disablePadding: false,
        isSelfClickable: true,
        label: 'optionsDropdown',
        alignRowCell: 'right',
    };
    const headCellsImpl = [...headCells, optionsHeadCell]
    const columnNames = headCells
        .filter(c => !c.isEmpty && !c.isSelfClickable)
        .map(c => ({ label: c.label, id: c.id }));

    function handleClickOptionsIcon(event: React.MouseEvent<HTMLElement>, row: RowType) {
        setAnchorEl(event.currentTarget);
        setSelectedDropdownRow(row);
        // select row to visually show the target
        setSelected([row]);
        if (onRowsSelected) onRowsSelected([row]);
    };

    function handleCloseOptionsDropdown() {
        setAnchorEl(null);
        setSelectedDropdownRow(null);
        setSelected([]);
        if (onRowsSelected) onRowsSelected([]);
    }

    function handleClickOption(kind: string | null) {
        setOptionsModal({ open: true, kind })
    }

    /* Can be modified to the multiple criterion */
    function handleFilterData(columnId: string, filterValue: string) {
        setRowFilters([
            { columnId: columnId, value: filterValue }
        ])
    }

    let rowsImpl = rows.filter((row) => {
        return rowFilters.every(
            ({ columnId, value }) => {
                const elm = row[columnId];
                if (elm && isInternalFileObjArrayType(elm)) {
                    return (elm as InternalFileObjType[])
                        .some((obj) => obj._id?.toString()?.toLowerCase().includes(value.toLowerCase()))
                }
                return elm?.toString()?.toLowerCase().includes(value.toLowerCase());
            }
        )
    })
    if (allowRename) {
        rowsImpl = rowsImpl.map((row) => ({
            ...row,
            'optionsDropdown':
                (<>
                    <IconButton onClick={(e) => handleClickOptionsIcon(e, row)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl && selectedDropdownRow && selectedDropdownRow[idHeadCell.id] === row[idHeadCell.id])}
                        onClose={handleCloseOptionsDropdown}
                    >
                        <MenuItem onClick={() => handleClickOption(OPTION_KINDS.rename)}>Rename</MenuItem>
                    </Menu>
                </>)
        }));
    }
    let OptionsModals: ReactNode;
    if (allowRename) {
        const testString = selectedDropdownRow ? (selectedDropdownRow?.files as InternalFileObjType[])[0]._id : "";
        const datasetNameRe = (/^(.+)(\.[^.]+\.[^.]+)/g).exec(testString);
        OptionsModals = (<>
            <BasicModal
                open={optionsModal.open}
                onClose={handleCloseOptionModal}
                aria-labelledby="options-modal-title"
                aria-describedby="options-modal-description"
                boxSx={styles.optionsModal}
            >
                <>
                    {optionsModal.kind === OPTION_KINDS.rename && (
                        <RenamePrompt
                            onCancel={handleCloseOptionModal}
                            onSubmit={(prefix: string, newPrefix: string) => handleFileRename(selectedDropdownRow?._id, prefix, newPrefix)}
                            originalName={datasetNameRe ? datasetNameRe[1] : ""}
                            originalNameSuffix={datasetNameRe ? datasetNameRe[2] : undefined}
                            title="Rename Dataset"
                            label="Dataset Name"
                        />
                    )}
                </>
            </BasicModal>
        </>);
    }

    let body = (<>
        {OptionsModals}
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar
                    selected={selected}
                    permanentToolbarIcons={permanentToolbarIcons}
                    selectedToolbarIcons={selectedToolbarIcons}
                    title={tableTitle}
                    filterColumnNames={columnNames}
                    filterDefaultColumn={columnNames[0]}
                    allowMultipleSelect={allowMultipleSelect}
                    onFilter={allowFilter ? handleFilterData : undefined}
                />
                <EnhancedTable
                    idHeadCell={idHeadCell}
                    headCells={headCellsImpl}
                    rows={rowsImpl}
                    selected={selected}
                    setSelected={setSelectedImpl}
                    allowMultipleSelect={allowMultipleSelect}
                />
            </Paper>
        </Box>
    </>);
    if (!asPage) return body;

    return (
        <Container>
            <PageHeader
                title='My Data' imageSrc="/assets/img/cards/data.webp"
                descriptions={['Upload, edit and view your saved data on the Simmunome platform.']} />
            {body}
        </Container>
    );
}
