import { useEffect, useState } from 'react';
import diseaseService from '_services/disease.service';
import '_css/home.css';
import history from '_helpers/history';
import config from '_configs';
import {
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Container,
  Box,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import PageHeader from '_components/base/PageHeader';
import { withServiceCallHandling } from '_helpers/decorators';

const initState = {
  start: false,
  step1: false,
  step2: false,
  confile: false,
};

const styles = {
  disabledText: {
    display: 'inline',
    color: '#969996',
  },
};

function capitilizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function TargetValidation() {
  const [diseases, setDiseases] = useState([]);
  const [selectedDisease, setSelectedDisease] = useState(null);
  const [analysisMode, setAnalysisMode] = useState('targeting');
  const [concFile, setConcFile] = useState(null);
  const [showElements, setShowElements] = useState({ ...initState, start: true });
  const { getAccessTokenSilently } = useAuth0();

  async function listDiseaseImpl() {
    const accessToken = await getAccessTokenSilently();
    return diseaseService.listDiseases(accessToken);
  }
  const listDisease = withServiceCallHandling(listDiseaseImpl, (data) => {
    setDiseases(data);
    setSelectedDisease('');
  });
  useEffect(() => {
    listDisease();
  }, []);

  async function uploadConcentrationFileImpl() {
    const accessToken = await getAccessTokenSilently();
    return diseaseService.uploadConcentrationFile(concFile, accessToken);
  }
  const handleSubmit = withServiceCallHandling(
    uploadConcentrationFileImpl,
    () => {
      history.push({
        pathname: config.clientUrls.GRAPHS_DISEASE(selectedDisease),
      });
    },
    undefined,
    false,
    () => analysisMode === 'concentration' && concFile,
  );

  async function appearDivs() {
    setShowElements({ ...initState, step1: true });
  }

  return (
    <Container>
      <PageHeader
        title="Target Validation"
        imageSrc="/assets/img/cards/targetvalidation.webp"
        descriptions={[
          'Validate that a molecular target has a chance of making an effect on a biological network by leveraging our AI disease graph algorithms.',
        ]}
      />

      <p>
        Welcome to the Simmunome disease network simulator. When selecting a disease, you will be taken to a
        visualization of its characteristic protein-protein interaction network. Clicking on a protein will yield
        specific information about its function and its connectivity to disease endpoints.
      </p>

      {showElements.start && (
        <Box textAlign="center">
          <Button variant="contained" onClick={() => appearDivs()}>
            Begin
          </Button>
        </Box>
      )}
      {showElements.step1 && (
        <Fade in={showElements.step1}>
          <div>
            <h2> Step 1.</h2>
            <div>
              <FormControl>
                <FormLabel id="controlled-radio-buttons-group">
                  <Typography variant="overline">Select analysis mode </Typography>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={analysisMode}
                  onChange={(e) => setAnalysisMode(e.target.value)}
                >
                  <FormControlLabel
                    value="targeting"
                    control={<Radio />}
                    label={
                      <>
                        Protein Targeting
                        <span style={styles.disabledText}> (Model the network effects of inhibiting a protein.)</span>
                      </>
                    }
                  />
                  <FormControlLabel
                    value="concentration"
                    control={<Radio />}
                    label={
                      <>
                        Concentration Prediction
                        <span style={styles.disabledText}> (Predict patient-level protein concentration.)</span>
                      </>
                    }
                  />
                </RadioGroup>
              </FormControl>

              <Fade in={analysisMode === 'concentration'}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button variant="contained" component="label">
                    Upload patient data
                    <input
                      hidden
                      accept=".txt,.csv"
                      type="file"
                      onChange={(e) => {
                        e.preventDefault();
                        setConcFile(e.target.files[0]);
                      }}
                    />
                  </Button>
                  {concFile && (
                    <>
                      <Box
                        sx={{
                          px: 5,
                          py: 1,
                          border: '1px dashed grey',
                          minWidth: '30%',
                          alignContent: 'center',
                        }}
                      >
                        filename:
                        {concFile.name}
                      </Box>
                      <Box />
                    </>
                  )}
                </Box>
              </Fade>
            </div>
          </div>
        </Fade>
      )}
      {!showElements.start && (
        <Fade in={!showElements.start}>
          <div>
            <h2> Step 2.</h2>
            <div>
              <FormControl>
                <FormLabel id="controlled-radio-buttons-group-diseases">
                  <Typography variant="overline">Select disease to analyze </Typography>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="controlled-radio-buttons-group-diseases"
                  name="controlled-radio-buttons-group-diseases"
                  value={selectedDisease}
                  onChange={(e) => setSelectedDisease(e.target.value)}
                >
                  {diseases.map((disease) => (
                    <FormControlLabel
                      key={disease}
                      value={disease}
                      control={<Radio />}
                      label={capitilizeFirstLetter(disease)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>

            {analysisMode === 'concentration' && concFile ? (
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                align="center"
                onClick={() => handleSubmit()}
                disabled={!selectedDisease || selectedDisease === ''}
              >
                Confirm
              </Button>
            ) : (
              <Button
                variant="contained"
                align="center"
                onClick={() =>
                  history.push({
                    pathname: config.clientUrls.GRAPHS_DISEASE(selectedDisease),
                  })
                }
                disabled={!selectedDisease || selectedDisease === ''}
              >
                Confirm
              </Button>
            )}
          </div>
        </Fade>
      )}
    </Container>
  );
}

export default TargetValidation;
