import { handleResponse } from '_helpers/middleware';

/**
 * lists diseases
 * @returns Promise (data=Array(diseases names))
 */
function listDiseases(accessToken) {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return fetch('/api/disease/available-diseases', requestOptions).then(handleResponse);
}

/**
 * Get data per disease to build a graph
 * In case concFilename is provided - use conc logic on the backend
 * @returns Promise
 */
function getDiseaseData(disease, concFilename, accessToken) {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return fetch(`/api/disease/graph-data/${disease}`, requestOptions).then(handleResponse);
}

/**
 * Get node info per disease (if any)
 * @returns Promise
 */
function getDiseaseNodeInfo(disease, nodeId, accessToken) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      nodes_ids: [nodeId],
    }),
  };
  return fetch(`/api/disease/nodes-info/${nodeId}`, requestOptions).then(handleResponse);
}

/**
 * Upload concentration file
 * @returns Promise
 */
function uploadConcentrationFile(file, accessToken) {
  const formData = new FormData();
  formData.append('concfile', file);
  const requestOptions = {
    method: 'POST',
    headers: { Authorization: `Bearer ${accessToken}` },
    body: formData,
  };
  return fetch('/api/concentration', requestOptions).then(handleResponse);
}

/**
 * Get Node Process Paths
 * @returns Promise
 */
function getNodeProcessPaths(disease, nodeId, accessToken) {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return fetch(`/api/diseases/${disease}/nodes/${nodeId}/process_paths`, requestOptions).then(handleResponse);
}

const diseaseService = {
  listDiseases,
  getDiseaseData,
  getDiseaseNodeInfo,
  getNodeProcessPaths,
  uploadConcentrationFile,
};

export default diseaseService;
