import { Button, Box, Divider, Accordion, AccordionSummary, AccordionDetails, AccordionActions } from '@mui/material';
import KeywordsChips from './KeywordsChips';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IndividualProteinInfo } from './TargetEfficacyInterface';

export default function NodeInfoAccordion(metaInfo: IndividualProteinInfo) {
  return (
    <Box key={metaInfo?.preferredName} sx={{ marginBottom: 1, marginTop: 1 }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>{metaInfo?.preferredName}</Typography>
          <Typography sx={{ color: 'text.secondary', marginLeft: 'auto', marginRight: 2 }}>View more</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider>
            <Typography sx={{ color: 'text.secondary' }}>Pathways</Typography>
          </Divider>
          <Box sx={{ mt: '5px' }}>{KeywordsChips(metaInfo?.keggpathways)}</Box>
          <Divider>
            <Typography sx={{ color: 'text.secondary' }}>Keywords</Typography>
          </Divider>
          <Box sx={{ mt: '5px' }}>{KeywordsChips(metaInfo?.keywords)}</Box>
        </AccordionDetails>
        <AccordionActions>
          <Button variant="contained" disabled>
            Download {metaInfo?.preferredName} data
          </Button>
        </AccordionActions>
      </Accordion>
    </Box>
  );
}
