import { ReactNode, useState, ChangeEvent } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { alpha } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import HideSourceIcon from '@mui/icons-material/HideSource';
import { FormControl, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { Refresh } from '@mui/icons-material';

import classes from './EnhancedTableToolbar.module.css';
import RowType, { RowElementType } from './RowType';

interface FilterColumnType {
    id: string;
    label: string;
}

export interface EnhancedTableToolbarProps {
    selected: RowType[];
    permanentToolbarIcons?: ReactNode;
    selectedToolbarIcons?: ReactNode;
    title: string;
    filterColumnNames?: FilterColumnType[];
    filterDefaultColumn?: FilterColumnType;
    onFilter?: (column: string, filterStr: string) => void;
    allowMultipleSelect?: boolean;
    allowSelect?: boolean;
}
/**
 * permanentToolbarIcons and selectedToolbarIcons define components in toolbar
 * that are responsible for the specific actions regardles selection of the row
 * (permanent)
 * OR when row(s) got selected
 *
 * For example  permanentToolbarIcons can be defined as follows
 *   <div>
 *      <Tooltip title="Upload a new data file">
 *          <IconButton onClick={onClickAdd}>
 *               <AddIcon />
 *          </IconButton>
 *     </Tooltip>
 * </div>
 *
 *  For example selectedToolbarIcons can be defined as follows
 *  <div>
 *       <Tooltip title="Delete">
 *           <IconButton onClick={onDeleteSelected}>
 *               <DeleteIcon />
 *           </IconButton>
 *       </Tooltip>
 *   </div >
 */
export default function EnhancedTableToolbar({
    selected,
    permanentToolbarIcons,
    selectedToolbarIcons,
    title,
    filterColumnNames = [],
    filterDefaultColumn = { id: 'name', label: 'Name' },
    allowMultipleSelect = true,
    allowSelect = true,
    onFilter,
}: EnhancedTableToolbarProps) {
    const numSelected: number = selected.length;
    const [isFilterVisible, setIsFilterVisible] = useState<Boolean>(false);
    const [filterInputStr, setFilterInputStr] = useState<string>('');
    const handleToggleFilter = () => {
        setIsFilterVisible(!isFilterVisible); // Toggle filter visibility
    };
    const [filterBy, setFilterBy] = useState<string>(filterDefaultColumn.id);
    function handleSelectChange(event: SelectChangeEvent) {
        setFilterBy(event.target.value);
    }

    function handleRefreshFilter() {
        setFilterBy(filterDefaultColumn.id);
        setFilterInputStr('');
        if (onFilter) onFilter(filterBy, '');
    }

    function handleFilterInputChange(event: ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        setFilterInputStr(value);
        if (onFilter) onFilter(filterBy, value);
    }

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(allowMultipleSelect &&
                    numSelected > 0 && {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
            }}
        >
            {allowSelect && allowMultipleSelect && numSelected > 0 ? (
                <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            )}

            {allowSelect && allowMultipleSelect && numSelected > 0 ? (
                <>{selectedToolbarIcons}</>
            ) : (
                <>
                    {onFilter && (
                        <>
                            {isFilterVisible && (
                                <TextField
                                    id="outlined-basic"
                                    label="Filter"
                                    variant="outlined"
                                    size="small"
                                    value={filterInputStr}
                                    placeholder="Filter value"
                                    onChange={handleFilterInputChange}
                                    sx={{ flex: '1 1 100%' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" variant="standard">
                                                <FormControl variant="standard">
                                                    <Select
                                                        disableUnderline
                                                        value={filterBy}
                                                        onChange={handleSelectChange}
                                                    >
                                                        {filterColumnNames.map((c: FilterColumnType) => (
                                                            <MenuItem key={c.id} value={c.id}>
                                                                {c.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleRefreshFilter}
                                                    edge="end"
                                                >
                                                    <Refresh />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}

                            <Tooltip title={isFilterVisible ? 'Hide filter field' : 'Filter list'}>
                                <IconButton onClick={handleToggleFilter}>
                                    {isFilterVisible ? (
                                        <HideSourceIcon />
                                    ) : (
                                        <div className={filterInputStr ? classes.filter_icon_with_value : ''}>
                                            <FilterListIcon />
                                        </div>
                                    )}
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </>
            )}
            <>{permanentToolbarIcons}</>
        </Toolbar>
    );
}
