export const graphTheme = {
  NODE_DEFAULT_COLOR: '#294559',
  EDGE_DEFAULT_COLOR: '#aaaaaa',
  MUTED_COLOR: '#ffffff00',
  SUPER_HIGHLIGHTED_COLOR: '#ffbbbb',
  PATH_HIGHLIGHTED_COLOR: '#ffaaaa',
  NODE_SELECTED_COLOR: '#3d923a',
  NODE_IN_PATH_COLOR: '#b3c9b3',
  NODE_OUTSIDE_PATH_COLOR: '#bbbbbb',
  BACKGROUND_COLOR: '#ffffff',
  ENDPOINT_COLOR: '#ff8080',
  NORMAL_COLOR: '#72b9f9',
  BORDER_DEFAULT: '#550055',
  BORDER_HIGHLIGHT: '#f03ede',
  AI_PRED_EDGE_COLOR: '#e6b000',
};
