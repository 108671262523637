import { handleResponse } from '_helpers/middleware';
import { generatePersonalSSEToken } from '_helpers/sse';

const URLS = {
  uploadFiles: '/api/files/upload',
  listFileUploads: '/api/files/uploads/',
  deleteFileUpload: (upload_id) => `/api/files/uploads/${upload_id}`,
  deleteFileUploads: '/api/files/uploads/',
  renameFilePrefix: (upload_id) => `/api/files/uploads/${upload_id}/rename_prefix`,
  getFiles: '/api/files/list',
  listFileUploadColumns: (upload_id) => `/api/files/uploads/${upload_id}/columns`,
  getFileColumns: '/api/files/columns',
  deleteFiles: '/api/files/delete',
  renameFile: '/api/files/rename',
  // TODO TOFIX react proxy causes Event listener to misbehave
  sseUploadFiles: `${!process.env.REACT_APP_API_SERVER_URL || process.env.REACT_APP_API_SERVER_URL.includes('localhost')
    ? 'http://127.0.0.1:5000'
    : ''
    }/api/preproc/sse/stream`,
};

/**
 * Upload a file
 * @returns Promise
 */
function uploadFiles(files, metadata, accessToken) {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append(file.name, file);
  });
  formData.append('metadata', JSON.stringify(metadata));
  // Gen a token to create "hidden" personal SSE channel
  const personalSSEToken = generatePersonalSSEToken();
  formData.append('personalSSEToken', personalSSEToken);

  const requestOptions = {
    method: 'POST',
    headers: { Authorization: `Bearer ${accessToken}` },
    body: formData,
  };
  return fetch(URLS.uploadFiles, requestOptions).then(handleResponse);
}

function listFileUploads(accessToken) {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken} ` },
  };
  return fetch(URLS.listFileUploads, requestOptions).then(handleResponse);
}

function listFileUploadColumns(accessToken, uploadId) {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken} ` },
  };
  return fetch(URLS.listFileUploadColumns(uploadId), requestOptions).then(handleResponse);
}

function deleteFileUpload(upload_id, accessToken) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken} `,
    },
  };
  return fetch(URLS.deleteFileUpload(upload_id), requestOptions).then(handleResponse);
}

function deleteFileUploads(upload_ids, accessToken) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken} `,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ "upload_ids": upload_ids }),
  };
  return fetch(URLS.deleteFileUploads, requestOptions).then(handleResponse);
}

function getFiles(accessToken) {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken} ` },
  };
  return fetch(URLS.getFiles, requestOptions).then(handleResponse);
}

function getFileColumns(filename, accessToken) {
  const url = new URL(URLS.getFileColumns, window.location.href);
  url.searchParams.set('filename', filename);
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken} ` },
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function deleteFiles(files, accessToken) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken} `,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(files),
  };
  return fetch(URLS.deleteFiles, requestOptions).then(handleResponse);
}

function renameFile(name, newName, accessToken) {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${accessToken} `,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      newName,
    }),
  };
  return fetch(URLS.renameFile, requestOptions).then(handleResponse);
}

function renameFilePrefix(upload_id, prefix, newPrefix, accessToken) {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${accessToken} `,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      prefix,
      newPrefix,
    }),
  };
  console.log(URLS.renameFilePrefix(upload_id))
  return fetch(URLS.renameFilePrefix(upload_id), requestOptions).then(handleResponse);
}

const fileService = {
  uploadFiles,
  listFileUploads,
  deleteFileUpload,
  deleteFileUploads,
  renameFilePrefix,
  listFileUploadColumns,
  // @deprecated
  getFiles,
  deleteFiles,
  getFileColumns,
  renameFile,
  URLS,
};

export default fileService;
