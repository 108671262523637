const config = {
  DEBUG: true,
  apiUrl: '/api',
  clientUrls: {
    TargetValidation: '/targetvalidation',
    HOME: '/',
    FILES: '/files',
    VISUALIZATION: '/visualization',
    VISUALIZATION_PROJECT: (projectId) => `/visualization/project/${projectId}`,
    TARGET_VALIDATION_PROJECT: (projectId) => `/target_validation/project/${projectId}`,
    VISUALIZATION_WITHOUT_FILE: '/visualization_without_file',
    PROFILE: '/profile',
    PROJECTS: '/projects',
    MODELS: '/models',
    SETTINGS: '/settings',
    HELP: '/help',
    GRAPHS_DISEASE: (disease, concFilename, aiPreds = 'true') =>
      `/graphs/${disease}` +
      (concFilename ? `?concFilename=${encodeURIComponent(concFilename)}` : '') +
      `&aipreds=${aiPreds}`,
    NODE_PROCESS_PATHS: (disease, nodeId) => `/node_process_paths/${disease}/${nodeId}`,
  },
};

export default config;
