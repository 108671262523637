import { create } from 'zustand';

export interface AiPredStore {
  filterValue: string;
  filterBooleanValue: boolean;
  filterCutoff: number;
  setValue: (value: string) => void;
  setCutoff: (value: number) => void;
}

const AiPredStore = create<AiPredStore>((set) => ({
  filterValue: 'true',
  filterBooleanValue: true,
  filterCutoff: 0.5,
  setValue: (value: string) => set(() => ({ filterValue: value, filterBooleanValue: value === 'true' ? true : false })),
  setCutoff: (value: number) => set(() => ({ filterCutoff: value })),
}));

export default AiPredStore;
