import useStore from "_helpers/store";
import fileService from "_services/files.service";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

interface SSEListenerType {
    channelId: string;
    url: string;
    failCallback?: () => void;
    successCallback?: () => void;
};

export default function SSEListener({ channelId, url, failCallback, successCallback }: SSEListenerType) {
    const delete_channel = useStore(state => state.delete_channel)
    useEffect(() => {
        console.log(`supposed to connect to  ${url}`)
        const toastId = toast.loading("Processing");
        const sse = new EventSource(url);
        sse.addEventListener(channelId, (e) => {
            const data = JSON.parse(e.data);
            console.log(data)
            const status = data?.status || 'loading';
            let render = 'Loading...';
            let _type = toast.TYPE.INFO;
            let isLoading = true;
            let progress = null;
            if (data.message) render = data.message;
            if (data.progress !== undefined) progress = data.progress / 100;
            if (data.call_fail_function && failCallback) failCallback();

            switch (status) {
                case 'failed': {
                    // toast.dismiss(toastId);
                    toast.error(render, { autoClose: false });
                    return;
                }
                case 'finish': {
                    toast.update(toastId, { render, type: toast.TYPE.SUCCESS, autoClose: 2000, progress, isLoading: false })
                    if (successCallback) {
                        successCallback();
                    }
                    break;
                }
                case 'terminated': {
                    toast.dismiss(toastId);
                    toast.error(render, { autoClose: false })
                    break;
                }
                default: {
                    toast.update(toastId, { render, type: _type, autoClose: false, isLoading, progress });
                    // quit instead of continue to dropping the channel
                    return;
                }
            }
            sse.close();
            delete_channel(channelId);
            console.log('DELETING CHANNEL!!!!');
            return;
        }, false);
        sse.addEventListener('error', function (event) {
            console.log(event);
            toast.error('Job has failed', { autoClose: false });
            sse.close();
            delete_channel(channelId);
        }, false);

        return () => {
            sse.close();
            delete_channel(channelId);
        };
    }, [channelId]);
    return (<></>);
}