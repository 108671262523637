import { graphTheme } from '_theme/graphTheme';

function refreshEdgesColor(graph) {
  graph.edges().forEach((edge) => {
    const { originalColor, hidden } = graph.getEdgeAttributes(edge);
    if (hidden) {
      graph.setEdgeAttribute(edge, 'color', graphTheme.MUTED_COLOR);
    } else {
      graph.setEdgeAttribute(edge, 'color', originalColor);
    }
  });
}

function refreshNodesColor(graph) {
  graph.nodes().forEach((node) => {
    const { originalColor, filteron, hidden } = graph.getNodeAttributes(node);
    if (hidden) {
      graph.setNodeAttribute(node, 'color', graphTheme.MUTED_COLOR);
    } else {
      graph.setNodeAttribute(node, 'color', originalColor);
      if (filteron) {
        graph.setNodeAttribute(node, 'color', graphTheme.BORDER_HIGHLIGHT);
      }
    }
  });
}

export function resetAllEdgesColor(sigma) {
  const graph = sigma.getGraph();
  refreshEdgesColor(graph);
  graph.edges().forEach((edge) => {
    graph.setEdgeAttribute(edge, 'size', 1);
  });
}

export function leaveAllNodes(sigma) {
  const graph = sigma.getGraph();

  graph.nodes().forEach((node) => {
    const { originalColor, originalLabel, filteron } = graph.getNodeAttributes(node);
    if (!filteron) {
      graph.removeNodeAttribute(node, 'highlighted');
      graph.setNodeAttribute(node, 'color', originalColor);
      graph.setNodeAttribute(node, 'label', originalLabel);
    } else {
      graph.setNodeAttribute(node, 'color', graphTheme.BORDER_HIGHLIGHT);
    }
  });
  resetAllEdgesColor(sigma);
}

export function createEdgeMap(nodes) {
  let edges = {};
  // Iterate over the nodes array, except the last one, because it has no outgoing edge
  for (let i = 0; i < nodes.length - 1; i++) {
    // Use the current node as a key and the next node as its value
    edges[nodes[i]] = nodes[i + 1];
  }
  return edges;
}

export function highlightPath(sigma, pathNodes) {
  const graph = sigma.getGraph();
  const nodes = new Set(pathNodes);
  const pathEdgeMap = createEdgeMap(pathNodes);

  pathNodes.forEach((curName) => {
    graph
      .edges(curName)
      .filter((edge) => {
        return nodes.has(edge.split('>')[0]) && edge.split('>')[1] === pathEdgeMap[edge.split('>')[0]];
      })
      .forEach((edge) => {
        graph.setEdgeAttribute(edge, 'color', graphTheme.SUPER_HIGHLIGHTED_COLOR);
        graph.setEdgeAttribute(edge, 'size', 3);
      });
    graph.nodes().forEach((node) => {
      if (node === pathNodes[0]) {
        graph.setNodeAttribute(node, 'color', graphTheme.NODE_SELECTED_COLOR);
      } else if (node === curName && node !== pathNodes[pathNodes.length - 1]) {
        graph.setNodeAttribute(node, 'color', graphTheme.NODE_IN_PATH_COLOR);
      }
    });
  });
}

export function toggleAiPredictedEdgesOnGraph(graph, isOn, cutoff) {
  // hide edges
  graph.edges().forEach((edge) => {
    const { predicted, relation } = graph.getEdgeAttributes(edge);
    if (predicted) {
      if (isOn) {
        if (relation >= cutoff) {
          graph.setEdgeAttribute(edge, 'hidden', false);
        } else {
          graph.setEdgeAttribute(edge, 'hidden', true);
        }
      } else {
        graph.setEdgeAttribute(edge, 'hidden', true);
      }
    }
  });
  refreshEdgesColor(graph);
  // hide nodes
  graph.nodes().forEach((node) => {
    var isNodeHidden = true;
    graph.edges(node).forEach((e) => {
      const { hidden } = graph.getEdgeAttributes(e);
      if (!hidden) {
        isNodeHidden = false;
      }
    });
    graph.setNodeAttribute(node, 'hidden', isNodeHidden);
  });
  refreshNodesColor(graph);
}

export function toggleAIPredictedEdges(sigma, isOn, cutoff) {
  const graph = sigma.getGraph();
  toggleAiPredictedEdgesOnGraph(graph, isOn, cutoff);
}

export function enterNode(sigma, selectedNode, external = false) {
  const graph = sigma.getGraph();
  leaveAllNodes(sigma);
  const highlightedNodes = graph.neighbors(selectedNode);
  // internally sigma highlights entered node. For external usage not
  if (external) {
    highlightedNodes.push(selectedNode);
  }
  const highligtedNeighbors = new Set(highlightedNodes);
  graph.nodes().forEach((node) => {
    if (node === selectedNode && !external) return;
    if (highligtedNeighbors.has(node)) {
      graph.setNodeAttribute(node, 'highlighted', true);
    } else {
      graph.setNodeAttribute(node, 'color', graphTheme.MUTED_COLOR);
      graph.setNodeAttribute(node, 'label', '');
    }
  });
  const highlitedEdges = new Set(graph.edges(selectedNode));
  graph
    .edges()
    .filter((edge) => !highlitedEdges.has(edge))
    .forEach((edge) => {
      graph.setEdgeAttribute(edge, 'color', graphTheme.MUTED_COLOR);
    });
}

export function leaveNode(sigma, selectedNode) {
  const graph = sigma.getGraph();
  const defaultEdgeColor = sigma.getSetting('defaultEdgeColor');
  const highligtedNeighbors = new Set(graph.neighbors(selectedNode));
  graph.nodes().forEach((node) => {
    if (node === selectedNode || highligtedNeighbors.has(node)) {
      graph.removeNodeAttribute(node, 'highlighted');
    } else {
      const { originalColor, originalLabel } = graph.getNodeAttributes(node);
      graph.setNodeAttribute(node, 'color', originalColor);
      graph.setNodeAttribute(node, 'label', originalLabel);
    }
  });
  const highlitedEdges = new Set(graph.edges(selectedNode));
  graph
    .edges()
    .filter((edge) => !highlitedEdges.has(edge))
    .forEach((edge) => {
      graph.setEdgeAttribute(edge, 'color', defaultEdgeColor);
    });
}

export function highlightFilteredNodes(sigma, nodesToHighlight) {
  const graph = sigma.getGraph();
  const nodesSet = new Set(nodesToHighlight);
  graph.nodes().forEach((node) => {
    const { originalColor } = graph.getNodeAttributes(node);
    if (nodesSet.has(node)) {
      graph.setNodeAttribute(node, 'color', graphTheme.BORDER_HIGHLIGHT);
      graph.setNodeAttribute(node, 'filteron', true);
    } else {
      graph.setNodeAttribute(node, 'color', originalColor);
      graph.setNodeAttribute(node, 'filteron', false);
    }
  });
}
