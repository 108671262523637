import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { Container } from '@mui/material';
import './frontpage.scss';
import { Typography } from '@mui/material';

const audience = process.env.REACT_APP_API_SERVER_URL;

export default function FrontPage() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div>
      <div className="wrapper">
        <div className="biglogo">
          <img src="/assets/img/logo-reverse-text.png" />
        </div>
        <div className="logincontainer">
          <Typography variant="h3">Leveraging generative AI to untangle biological complexity.</Typography>
          <Container sx={{ margin: 'auto', height: '50%', display: 'flex' }}>
            <Button size="large" variant="contained" onClick={() => loginWithRedirect()}>
              Login
            </Button>
          </Container>
        </div>
      </div>
      <Typography sx={{ paddingBottom: 20 }}>Audience build: {audience}</Typography>
    </div>
  );
}
