import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { Box, Chip, Container, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import HeadCellType from '_components/base/table/HeadCellType';
import RowType, { RowElementType } from '_components/base/table/RowType';
import { ReactNode, useEffect, useState } from 'react';
import modelsService from '_services/harbor/models.service';
import { useAuth0 } from '@auth0/auth0-react';
import { useIsMounted } from '_hooks/useIsMounted';
import ContentTable from '_components/base/table/ContentTable';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import BasicModal from '_components/base/BasicModal';
import CreateModelRoutine from './CreateModelRoutine';
import AlertDialog from '_components/base/AlertDialog';
import CreateFixTVProject from '_pages/Projects/routines/CreateFixTVProject';
import { isInternalIdNameObjArrayType } from '_components/base/table/InternalIdNameObjAPI';
import PageHeader from '_components/base/PageHeader';
import { withServiceCallHandling } from '_helpers/decorators';
import BaseModels from './BaseModels';
import baseModelsServices from '_services/harbor/basemodels.service';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                // <Box>
                <Box sx={{ pt: 1 }}>{children}</Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const styles = {
    insertModalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    },
};
const defaultIdHeadCell: HeadCellType = {
    id: '_id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
};
const defaultHeadCells: HeadCellType[] = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'version', numeric: false, disablePadding: false, label: 'Version' },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'Date',
        isDate: true,
    },
    { id: 'disease', numeric: false, disablePadding: false, label: 'Disease' },
    {
        id: 'projects',
        numeric: false,
        disablePadding: false,
        label: 'Projects in use',
        renderElementImpl: (models: RowElementType) => {
            if (!isInternalIdNameObjArrayType(models)) return undefined;
            return (
                <Stack direction="column" spacing={0.1}>
                    {models.map((m) => (
                        <Chip key={m._id} label={m.name} size="small" />
                    ))}
                </Stack>
            );
        },
    },
];

enum CreateStages {
    ModelCreated = 'modelcreated',
    ToProject = 'toproject',
    ProjectCreated = 'projectcreated',
}

type NewModelObjType = {
    id?: string;
    name?: string;
    stage?: CreateStages;
};

export interface ModelsProps {
    asPage?: boolean;
    tableTitle?: string;
    entityName?: string;
    allowInsert?: boolean;
    allowDelete?: boolean;
    allowFilter?: boolean;
    allowRename?: boolean;
    allowCreateProject?: boolean;
    allowMultipleSelect?: boolean;
    initRows?: RowType[];
    initRowsSelected?: RowType[];
    headCells?: HeadCellType[];
    idHeadCell?: HeadCellType;
    onRowsSelected?: (rows: RowType[]) => void;
}
function Models({
    asPage = true,
    initRows = [],
    initRowsSelected = [],
    allowMultipleSelect = true,
    allowInsert = true,
    allowDelete = true,
    allowFilter = true,
    allowRename = true,
    allowCreateProject = true,
    tableTitle = 'Models',
    entityName = 'Model',
    headCells = defaultHeadCells,
    idHeadCell = defaultIdHeadCell,
    onRowsSelected,
}: ModelsProps) {
    const isMounted = useIsMounted();
    const { getAccessTokenSilently } = useAuth0();

    const [rows, setRows] = useState<RowType[]>(initRows);
    const [baseModels, setBaseModels] = useState<RowType[]>([]);
    const [tabValue, setTabValue] = useState<number>(0);
    const [rowsSelected, setRowsSelected] = useState<RowType[]>(initRowsSelected);
    const [insertModalOpen, setInsertModalOpen] = useState<boolean>(false);
    const [newModelAlert, setNewModelAlert] = useState<NewModelObjType>({});

    async function listModelsImpl() {
        const accessToken = await getAccessTokenSilently();
        return modelsService.listModels(accessToken);
    }
    const listModels = withServiceCallHandling(
        listModelsImpl,
        (data) => setRows(data),
        undefined,
        false,
        () => isMounted(),
    );

    async function listBaseModelsImpl() {
        const accessToken = await getAccessTokenSilently();
        return baseModelsServices.listBaseModels(accessToken);
    }
    const listBaseModels = withServiceCallHandling(
        listBaseModelsImpl,
        (data) => setBaseModels(data),
        undefined,
        false,
        () => isMounted(),
    );

    useEffect(() => {
        listModels();
        if (asPage) listBaseModels();
    }, []);

    async function renameModelImpl(row: RowType, name: string, newName: string) {
        const accessToken = await getAccessTokenSilently();
        return modelsService.renameModel(accessToken, row[idHeadCell.id], name, newName);
    }

    const renameModel = withServiceCallHandling(
        renameModelImpl,
        (data) => {
            const newRows: RowType[] = rows.map((r) => {
                if (r[idHeadCell.id] !== data._id) return r;
                return { ...r, name: data.newName };
            });
            setRows(newRows);
        },
        undefined,
        'Renaming...',
        () => isMounted(),
    );

    const handleModalOpen = () => {
        setInsertModalOpen(true);
    };
    const handleModalClose = () => {
        setInsertModalOpen(false);
        setNewModelAlert({});
    };

    function handleRowsSelected(selRows: RowType[]) {
        if (onRowsSelected) onRowsSelected(selRows);
        setRowsSelected(selRows);
    }

    async function deleteModelsImpl() {
        if (!rowsSelected || rowsSelected.length < 1) return;
        const accessToken = await getAccessTokenSilently();
        const rowIds = rowsSelected.map((row) => row._id);
        return modelsService.deleteModels(accessToken, rowIds);
    }

    const handleDeleteSelected = withServiceCallHandling(
        deleteModelsImpl,
        (data) => {
            const newRows = rows.filter((row) => !data.model_ids.includes(row[idHeadCell.id]));
            setRows(newRows);
            handleRowsSelected([]);
        },
        undefined,
        false,
        () => isMounted() && rowsSelected && rowsSelected.length > 0,
    );

    const DeleteRecordComponent: ReactNode = (
        <div>
            <Tooltip title="Delete">
                <IconButton onClick={handleDeleteSelected}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </div>
    );

    const InsertRecordComponent: ReactNode = (
        <div>
            <Tooltip title="Create new model">
                <IconButton onClick={handleModalOpen}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            {allowCreateProject && (
                <AlertDialog
                    title={`New Project for model ${newModelAlert.name}`}
                    message={`Do you want to create a new Target Validation project for the model ${newModelAlert.name}?`}
                    open={Boolean(newModelAlert.id) && newModelAlert.stage === CreateStages.ModelCreated}
                    onYes={() => {
                        setNewModelAlert({ ...newModelAlert, stage: CreateStages.ToProject });
                    }}
                    onNo={() => {
                        listModels();
                        handleModalClose();
                    }}
                />
            )}

            <BasicModal
                open={insertModalOpen && newModelAlert.stage !== CreateStages.ModelCreated}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                boxSx={styles.insertModalStyle}
            >
                <>
                    {!newModelAlert.stage && (
                        <CreateModelRoutine
                            onCancel={handleModalClose}
                            onSubmit={(newModelId: string, newModelName: string) => {
                                if (!allowCreateProject) {
                                    listModels();
                                    handleModalClose();
                                } else {
                                    setNewModelAlert({
                                        id: newModelId,
                                        name: newModelName,
                                        stage: CreateStages.ModelCreated,
                                    });
                                }
                            }}
                        />
                    )}

                    {allowCreateProject && newModelAlert.stage === CreateStages.ToProject && newModelAlert.id && (
                        <CreateFixTVProject
                            modelId={newModelAlert.id}
                            modelName={newModelAlert?.name || ''}
                            onCancel={handleModalClose}
                            onSubmit={(projectId: string) => {
                                listModels();
                                handleModalClose();
                            }}
                        />
                    )}
                </>
            </BasicModal>
        </div>
    );

    const modelBody = (
        <ContentTable
            idHeadCell={idHeadCell}
            headCells={headCells}
            rows={rows}
            rowsSelected={rowsSelected}
            onRowsSelected={handleRowsSelected}
            InsertRecordComponent={allowInsert ? InsertRecordComponent : undefined}
            DeleteRecordComponent={allowDelete ? DeleteRecordComponent : undefined}
            renameRecordFunction={allowRename ? renameModel : undefined}
            tableTitle={tableTitle}
            entityName={entityName}
            allowMultipleSelect={allowMultipleSelect}
        />
    );
    if (!asPage) return modelBody;

    const handleChangeTabValue = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const body = (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChangeTabValue} aria-label="basic tabs example">
                    <Tab label="User Models" />
                    <Tab label="Base Models" />
                </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
                {modelBody}
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
                <BaseModels initRows={baseModels} />
            </CustomTabPanel>
        </>
    );
    return (
        <Container>
            <PageHeader
                title="Models"
                imageSrc="/assets/img/cards/models.webp"
                descriptions={['View our available disease models or create your own customized data-driven models.']}
            />
            {body}
        </Container>
    );
}

export default Models;
