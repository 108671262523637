import { handleResponse } from '_helpers/middleware';
import { ProjectType } from '_pages/Projects/ProjectTypeEnum';

const URLS = {
    listProjects: '/api/harbor/projects',
    getProject: (projectId) => `/api/harbor/projects/${projectId}`,
    saveProject: (projectId) => `/api/harbor/projects/${projectId}/save_as_regular`,
    createProject: '/api/harbor/projects',
    deleteProject: (projectId) => `/api/harbor/projects/${projectId}`,
    deleteProjects: '/api/harbor/projects',
    renameProject: (projectId) => `/api/harbor/projects/${projectId}/rename`,
};

function listProjects(accessToken, type) {
    const url = new URL(URLS.listProjects, window.location.href);
    if (type) {
        url.searchParams.set('type', type);
    }
    const requestOptions = {
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken} ` },
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function getProject(accessToken, projectId, type) {
    const url = new URL(URLS.getProject(projectId), window.location.href);
    if (type) url.searchParams.set('type', type);
    const requestOptions = {
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken} ` },
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function getVisProject(accessToken, projectId) {
    return getProject(accessToken, projectId, ProjectType.Visualization)
}

/**
 * Save Project by assigning a proper name and converting project from default to a regular
 */
function saveProject(accessToken, projectId, projectName) {
    const requestOptions = {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name: projectName,
        }),
    };
    return fetch(URLS.saveProject(projectId), requestOptions).then(handleResponse);
}

function createTVProject(accessToken, projectName, modelId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name: projectName,
            data: {
                model_id: modelId,
            },
            type: ProjectType.TargetValidation
        }),
    };
    return fetch(URLS.createProject, requestOptions).then(handleResponse);
}

function createVisProject(accessToken, projectName, { uploadId, groupColumn, groupValues, isDefault = true }) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name: projectName,
            data: { uploadId, groupColumn, groupValues, isDefault },
            type: ProjectType.Visualization
        }),
    };
    return fetch(URLS.createProject, requestOptions).then(handleResponse);
}

function deleteProject(accessToken, projectId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${accessToken}` },
    };
    return fetch(URLS.deleteProject(projectId), requestOptions).then(handleResponse);
}

function renameProject(accessToken, projectId, name, newName) {
    const requestOptions = {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, newName }),
    };
    return fetch(URLS.renameProject(projectId), requestOptions).then(handleResponse);
}

function deleteProjects(accessToken, projectIds) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_ids: projectIds }),
    };
    return fetch(URLS.deleteProjects, requestOptions).then(handleResponse);
}

const projectsService = {
    listProjects,
    createTVProject,
    createVisProject,
    getVisProject,
    deleteProject,
    deleteProjects,
    renameProject,
    saveProject,
    URLS,
};

export default projectsService;
