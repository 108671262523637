import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "@mui/material";
import ButtonGroup from "_components/base/ButtonGroup";
import RowType from "_components/base/table/RowType";
import { useIsMounted } from "_hooks/useIsMounted";
import FileUploads from "_pages/FileUploads/FileUploads";
import uploadsService from "_services/harbor/uploads.service";
import { useEffect, useState } from "react";
import ManageGroupColumn from "./ManageGroupColumn";
import InternalFileObjType from "_components/base/table/InternalFileObj";
import { withServiceCallHandling } from "_helpers/decorators";

interface VizRoutineProps {
    onSubmit: (upload_id: string, column: string, columnValues: string[]) => void;
    onCancel: () => void;
}

enum ScreenTypes {
    Dataset = 1,
    Group = 2
}

export default function VizRoutine({ onSubmit, onCancel }: VizRoutineProps) {
    const theme = useTheme();
    const { getAccessTokenSilently } = useAuth0();
    const isMounted = useIsMounted();
    const [fileUploadRows, setFileUploadRows] = useState<RowType[]>([]);
    const [selectedFileUploadsRows, setSelectedFileUploadsRows] = useState<RowType[]>([]);
    const [screen, setScreen] = useState<ScreenTypes>(ScreenTypes.Dataset);
    async function listUploadsImpl() {
        const accessToken = await getAccessTokenSilently();
        return uploadsService.listUploads(accessToken);
    }
    const listUploads = withServiceCallHandling(
        listUploadsImpl,
        (data) => { setFileUploadRows(data) },
        undefined, false,
        () => isMounted()
    );
    useEffect(() => {
        listUploads();
    }, []);

    let body;
    if (screen === ScreenTypes.Dataset) {
        body = <>
            <FileUploads asPage={false}
                tableTitle='Add new or select existing dataset'
                allowDelete={false} allowMultipleSelect={false} allowRename={false}
                initRows={fileUploadRows}
                initRowsSelected={selectedFileUploadsRows}
                onRowsSelected={setSelectedFileUploadsRows}
            />
            <ButtonGroup
                isNextVisible={true}
                isBackVisible={false}
                isCreateVisible={false}
                isNextDisabled={selectedFileUploadsRows.length !== 1}
                onNext={() => setScreen(ScreenTypes.Group)}
                onBack={onCancel}
            />
        </>
    } else if (screen === ScreenTypes.Group) {
        if (selectedFileUploadsRows.length === 1) {
            const selectedRow = selectedFileUploadsRows[0];
            const testString = (selectedRow?.files as InternalFileObjType[])[0]._id || "";
            const datasetNameRe = (/^(.+)(\.[^.]+\.[^.]+)/g).exec(testString);
            const prefix = datasetNameRe ? datasetNameRe[1] : "";
            const selectedUploadId = selectedFileUploadsRows[0]._id as string;
            body = <ManageGroupColumn
                selectedUploadId={selectedUploadId}
                selectedFileSize={selectedRow.size as number}
                selectedFilenamePrefix={prefix}
                onSubmit={(column, columnValues) => onSubmit(selectedUploadId, column, columnValues)}
                onCancel={() => setScreen(ScreenTypes.Dataset)}
            />
        }
    }

    return <>
        {body}
    </>
}