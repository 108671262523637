import { create } from 'zustand';

export interface SSEChannelType {
    id: string;
    url: string;
    status?: string;
    failCallback?: () => void;
    successCallback?: () => void;
}

export interface StoreState {
    sse_channels: SSEChannelType[];
    add_channel: (id: string, url: string, failCallback?: () => void, successCallback?: () => void) => void;
    update_channel: (id: string, status: string) => void;
    delete_channel: (id: string) => void;
}

const useStore = create<StoreState>()(
    (set) => ({
        sse_channels: [],
        add_channel: (id, url, failCallback, successCallback) => set(
            (state) => ({ sse_channels: [...state.sse_channels, { id, url, failCallback, successCallback, status: 'loading' }] })
        ),
        update_channel: (id, status) => set(
            (state) => ({
                sse_channels: state.sse_channels
                    .map(ch => {
                        if (ch.id != id) return ch;
                        return { ...ch, status }
                    })
            })
        ),
        delete_channel: (id) => set(
            (state) => ({
                sse_channels: state.sse_channels
                    .filter(ch => ch.id != id)
            })
        ),
    })
);

export default useStore;