import { handleResponse } from '_helpers/middleware';

/**
 * gets shortest paths to endpoints for a target
 * @returns Promise (data=Array(Array(node name)))
 */
function getShortestPaths(disease, target, aiPredFilter, aiPredFilterCutoff, accessToken) {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const url = new URL('/api/target-validation/target-shortest-paths', window.location.href);
  url.searchParams.set('disease', disease);
  url.searchParams.set('target', target);
  url.searchParams.set('aiPredFilter', aiPredFilter);
  url.searchParams.set('aiPredFilterCutoff', String(aiPredFilterCutoff));
  return fetch(url.toString(), requestOptions).then(handleResponse);
}

/**
 * gets scores to endpoints for a target
 * @returns
 */
async function getScores(disease, target, aiPredFilter, aiPredFilterCutoff, accessToken) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      disease: disease,
      targets: [target],
      aiPredFilter: aiPredFilter,
      aiPredFilterCutoff: String(aiPredFilterCutoff),
    }),
  };
  const url = new URL('/api/target-validation/targets-efficacy', window.location.href);
  return await fetch(url.toString(), requestOptions).then(handleResponse);
}

const targetValidationService = {
  getShortestPaths,
  getScores,
};

export default targetValidationService;
